import React from "react";
import { useLocation } from "react-router-dom";

import OkaymonMobile from "../../assets/Okaymon3.avif";
import PlayersMobile from "../../assets/PlayersMobile.png";
import TypesMobile from "../../assets/TypesMobile.png";
import FAQsMobile from "../../assets/FAQsMobile.png";
import ContactMobile from "../../assets/ContactMobile.png";

function MobileHeader() {
  const location = useLocation();

  return (
    <div className="mobile-header-wrapper">
      <div className="headerBG"></div>
      <div className="mobile-header">
        <nav className="mobile-nav">
          <ul className="mobile-nav-list">
            <li className="mobile-nav-item">
              <a href="/">
                <img
                  src={OkaymonMobile}
                  alt="Home"
                  className={`mobile-nav-image ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                />
              </a>
            </li>
            <li className="mobile-nav-item">
              <a href="/okaymon">
                <img
                  src={PlayersMobile}
                  alt="Players"
                  className={`mobile-nav-image ${
                    location.pathname === "/okaymon" ? "active" : ""
                  }`}
                />
              </a>
            </li>
            <li className="mobile-nav-item">
              <a href="/types">
                <img
                  src={TypesMobile}
                  alt="Types"
                  className={`mobile-nav-image ${
                    location.pathname === "/types" ? "active" : ""
                  }`}
                />
              </a>
            </li>
            <li className="mobile-nav-item">
              <a href="/faqs">
                <img
                  src={FAQsMobile}
                  alt="FAQs"
                  className={`mobile-nav-image ${
                    location.pathname === "/faqs/" ? "active" : ""
                  }`}
                />
              </a>
            </li>
            <li className="mobile-nav-item">
              <a href="/contact">
                <img
                  src={ContactMobile}
                  alt="Contact"
                  className={`mobile-nav-image ${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default MobileHeader;
