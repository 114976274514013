import React from "react";
import ImageRow from "./ImageRow";
import PlayerStats from "./PlayerStats";
/*import GroupTextBox from "./GroupTextBox";*/
import TitleBar from "./TitleBar";
import TypeContainer, { typeColors } from "../Types/TypeContainer";
import "../../App.css";
import instagramLogo from "../../assets/iglogo.svg";

const GroupContainerMobile = ({ groupData }) => (
  <div className="group-container-mobile">
    {groupData.players.map((player) => (
      <div className="player-column" key={player.id}>
        <TitleBar
          key={player.id}
          title={player.name}
          comp={player.comp}
          color1={player.color1}
          color2={player.color2}
        />
        <ImageRow images={[player]} />
        <TypeContainer types={[player.type1, player.type2]} />
        <PlayerStats
          stats={player.stats}
          typeColor={typeColors[player.type1]?.color}
          typeColorB={typeColors[player.type1]?.colorB}
          typeColorC={typeColors[player.type1]?.colorC}
          typeColorD={typeColors[player.type1]?.colorD}
          borderColor={
            typeColors[player.type2]?.colorC || typeColors[player.type1]?.colorD
          }
        />
      </div>
    ))}
    <div className="groupFooter">
      <a href={groupData.footerLink} target="_blank" rel="noopener noreferrer">
        {groupData.illImage && (
          <img
            src={groupData.illImage}
            alt="Group Illustration"
            className="group-illustration"
          />
        )}
        <img src={instagramLogo} alt="Instagram" className="instagram-logo" />
        {groupData.footerText}
      </a>
    </div>
  </div>
);

export default GroupContainerMobile;
