import React from "react";
/*import CustomGrid from "../CustomGrid/CustomGrid";*/
import Embiid from "../../assets/EmbiidCover.avif";
import Giannis from "../../assets/GiannisCover.avif";
import Jokic from "../../assets/JokicCover.avif";

function Intro() {
  return (
    <div className="content">
      <div className="intro-text">
        <h1>WHAT IS OKAYMON?</h1>
        <div className="coverRow">
          <div className="gba-wrapper">
            <img src={Embiid} alt="Embiid" className="GBA" />
          </div>
          <div className="gba-wrapper">
            <img src={Giannis} alt="Giannis" className="GBA" />
          </div>
          <div className="gba-wrapper">
            <img src={Jokic} alt="Jokic" className="GBA" />
          </div>
        </div>
        <p>
          Okaymon is a math/art/music project that was inspired by my
          multi-decade love of the NBA and Pokémon, both of which unfortunately
          have no affiliation with this whatsoever. Lemme hit you with a quick
          breakdown of the project.
        </p>
        <p>
          <ul class="introList">
            <li>
              17 Pokémon types (Fire, Water, Electric, etc.) were reimagined as
              basketball play styles like Heat Check, Flow, and Highlight.
            </li>
            <li>
              The 6 Pokémon attributes (HP, Atk, Def, Special Atk, Special Def,
              and Speed) were reimagined as basketball skills/attributes
              (Health, Inside O, Inside D, Perimeter O, Perimeter D, and…Speed).
            </li>
            <li>
              Each of the new corresponding play styles and attributes were then
              defined by using a <i>ton</i> of camera tracking data and advanced
              metrics to create unique profiles that players could fit into
              depending on their own individual stats from dataset of around the
              last ~7 seasons.
            </li>
            <li>
              All active NBA players and their associated data were then run
              through a sorting algorithm that showed how similar their own play
              style was to the newly defined Okaymon type play styles.
            </li>
            <li>
              These players were then put through a second sorting algorithm
              that matched them to which of the original 151 Pokémon they are
              most similar to based on their peak play of the past ~7 seasons
              and corresponding types/play styles and attributes/skills.
            </li>
            <li>
              After each player was matched to a Pokémon the list was cut down
              to the strongest matches, with some creative liberties being taken
              to break a few ties.
            </li>
            <li>
              Concepts for what each player would look like if they became
              characters in this game world were written up and I was able to
              connect with some extremely talented illustrators to help bring
              those designs to life.
            </li>
            <li>
              A game is no good without a soundtrack and I was very fortunate to
              be able to collaborate with Gatex for a continually growing
              Okaymon OST.
            </li>
          </ul>
        </p>{" "}
        <p>
          That's more or less the gist of it, but very shortly I will put up a
          more thorough explanation of all the processes behind the project for
          anyone who wants to read more about that stuff, aka
          <a
            style={{ padding: 4, color: "cadetblue" }}
            href="https://x.com/KDTrey5/status/1184140634208329728"
          >
            people who want to look at graphs while having a hoop convo.
          </a>
          <p>
            In the meantime I have made sure to include all the most relevant
            stats used for each type over on the
            <a
              style={{ padding: 4, color: "cadetblue" }}
              href="https://okaymon.com/types"
            >
              Types
            </a>
            page, along with a description of each play style's strengths,
            weaknesses, and historical comparisons.
          </p>
        </p>
        <p>
          There are many Okaymon/players left to be illustrated and released
          over the course of this season and some surprises to come, so if you
          like this then please pop in regularly for the new additions and news,
          and/or follow the{" "}
          <a
            style={{ padding: 1, color: "cadetblue" }}
            href="https://okaymon.com/contact"
          >
            socials
          </a>{" "}
          for updates. Thanks for checking this out!
        </p>
        <p></p>
      </div>
    </div>
  );
}
export default Intro;
