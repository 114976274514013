import React, { useState } from "react";
import FAQs2 from "./FAQs2";

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "Will you release the data when the project is completed?",
      answer: (
        <p>
          Since the majority of the data that was used to build out the type
          profiles is behind pay walls and is proprietary, I won't be releasing
          it. Not mine to put out there and want to make sure the places I got
          it from are being supported. If you're interested in knowing what data
          was used, each type description page has two tables that show which
          data points were weighed most heavily for every type's offensive and
          defensive profiles.
        </p>
      ),
      open: false,
    },
    {
      question: "Was AI used for any of the art?",
      answer: (
        <p>
          No AI was used for any of the art or music in this project whatsoever.
          It is extremely important to me for anything on the art side of
          Okaymon to be done by real illustrators, artists, and musicians. You
          can find links to the card illustrators at the bottom each player card
          section.
        </p>
      ),
      open: false,
    },
    {
      question: "Are there prints or cards of the player art for sale?",
      answer: (
        <p>
          Not yet but there have been a lot of requests for it, so I'm in the
          process of figuring that out and will try to flag down everyone who
          asked about it. I'll also put up a form to join a newsletter in the
          next week so you can be notified when that is available so you don't
          have to keep checking back on the site or look at my social media (god
          forbid).
        </p>
      ),
      open: false,
    },
    {
      question: "Is this a real game I can play?",
      answer: (
        <p>
          This isn't a game or something you can play. Not right now at least. I
          do have a script written up to work off of if the time comes to pick
          up a project like that.
        </p>
      ),
      open: true,
    },
    {
      question: "Are you looking for more illustrators for the player cards?",
      answer: (
        <p>
          I'm always interested in bringing more illustrators to the project!
          Feel free to shoot me an e-mail at evan@okaymon.com with your
          portfolio and whatever else you'd like to throw out there - can go to
          the contact page and shoot me a message through that to make it easy
          too.
        </p>
      ),
      open: false,
    },
    {
      question:
        "Do you have all 151 players done or just the ones on the players page?",
      answer: (
        <p>
          All 151 players have been mapped out already and over the course of
          the season they'll be released as their art is finished!
        </p>
      ),
      open: false,
    },
    {
      question: "Will you tell me who each player is linked to?",
      answer: (
        <p>
          It's supposed to be a surprise! If you just HAD to know I'd let you in
          on who your favorite player is if you swore to secrecy though.
          Maybe...
        </p>
      ),
      open: false,
    },
    {
      question: "What is BlogBoy?",
      answer: (
        <p>
          <a href="https://www.theringer.com/nba/2018/4/14/17237990/blog-boy-kevin-durant-bill-simmons-warriors-shirt">
            A Kevin Durant-ism.
          </a>
        </p>
      ),
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : false,
      }))
    );
  };
  return (
    <div className="faq-container">
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQs2 faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
