import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./SidebarLeft.css";
import Okaymon from "../../assets/Okaymon.png";
import Players from "../../assets/Players.png";
import Types from "../../assets/Types.png";
import FAQs from "../../assets/FAQs.png";
import Contact from "../../assets/Contact.png";
import MobileHeader from "./MobileHeader";

function SidebarLeft() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return <MobileHeader />;
  }

  return (
    <div className="sidebar-left">
      <nav className="nav">
        <ul className="nav-list">
          <li className="nav-item players-icon">
            <a href="/">
              <img
                src={Okaymon}
                alt="Home"
                className={`nav-image ${
                  location.pathname === "/" ? "active" : ""
                }`}
              />
              <button
                className={`nav-button ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </button>
            </a>
          </li>
          <li className="nav-item players-icon">
            <a href="/okaymon">
              <img
                src={Players}
                alt="Players"
                className={`nav-image ${
                  location.pathname === "/okaymon" ? "active" : ""
                }`}
              />
              <button
                className={`nav-button ${
                  location.pathname === "/okaymon" ? "active" : ""
                }`}
              >
                Players
              </button>
            </a>
          </li>
          <li className="nav-item types-icon">
            <a href="/types">
              <img
                src={Types}
                alt="Types"
                className={`nav-image ${
                  location.pathname === "/types" ? "active" : ""
                }`}
              />
              <button
                className={`nav-button ${
                  location.pathname === "/types" ? "active" : ""
                }`}
              >
                Types
              </button>
            </a>
          </li>
          <li className="nav-item faqs-icon">
            <a href="/faqs">
              <img
                src={FAQs}
                alt="FAQs"
                className={`nav-image ${
                  location.pathname === "/faqs/" ? "active" : ""
                }`}
              />
              <button
                className={`nav-button ${
                  location.pathname === "/faqs/" ? "active" : ""
                }`}
              >
                FAQs
              </button>
            </a>
          </li>
          <li className="nav-item contact-icon">
            <a href="/contact">
              <img
                src={Contact}
                alt="Contact"
                className={`nav-image ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              />
              <button
                className={`nav-button ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact & Links
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SidebarLeft;
