import React, { useState, useEffect } from "react";
import { typeColors } from "../Types/TypeContainer";

function ImageRow({ images }) {
  const [lightbox, setLightbox] = useState({ open: false, src: "" });

  const openLightbox = (src) => {
    setLightbox({ open: true, src });
  };

  const closeLightbox = (e) => {
    if (
      e.target.classList.contains("lightbox") ||
      e.target.classList.contains("lightbox-img")
    ) {
      setLightbox({ open: false, src: "" });
    }
  };

  const getColors = (type1, type2) => {
    const primaryTypeColor =
      typeColors[type1]?.colorB || typeColors[type1]?.color;
    const secondaryTypeColor = typeColors[type2]?.colorB || primaryTypeColor;
    return {
      color1: primaryTypeColor,
      color2: secondaryTypeColor,
    };
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      const card = e.currentTarget;
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;

      const deltaX = x - centerX;
      const deltaY = y - centerY;
      const rotateX = (deltaY / centerY) * 10;
      const rotateY = (deltaX / centerX) * -10;

      const pos = [e.clientX - rect.left, e.clientY - rect.top];
      const l = pos[0];
      const t = pos[1];
      const h = card.offsetHeight;
      const w = card.offsetWidth;
      const px = Math.abs(Math.floor((100 / w) * l) - 100);
      const py = Math.abs(Math.floor((100 / h) * t) - 100);
      const pa = 50 - px + (50 - py);
      const lp = 50 + (px - 50) / 1.5;
      const tp = 50 + (py - 50) / 1.5;
      const px_spark = 50 + (px - 50) / 7;
      const py_spark = 50 + (py - 50) / 7;
      const p_opc = 20 + Math.abs(pa) * 1.5;

      card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
      card.style.setProperty("--grad-pos", `${lp}% ${tp}%`);
      card.style.setProperty("--sprk-pos", `${px_spark}% ${py_spark}%`);
      card.style.setProperty("--opacity", `${p_opc / 100}`);
    };

    const handleMouseLeave = (e) => {
      const card = e.currentTarget;
      card.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)`;
      card.style.removeProperty("--grad-pos");
      card.style.removeProperty("--sprk-pos");
      card.style.removeProperty("--opacity");
    };

    const cards = document.querySelectorAll(".card-container");
    cards.forEach((card) => {
      card.addEventListener("mousemove", handleMouseMove);
      card.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mousemove", handleMouseMove);
        card.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <div className="image-row">
      {images.map((img) => {
        const { color1, color2 } = getColors(img.type1, img.type2);
        return (
          <div
            id={`card-${img.id}`}
            className="card-container"
            key={img.id}
            data-type1={img.type1}
            data-type2={img.type2 || img.type1} // Fallback to type1 if type2 is not available
            onClick={() => openLightbox(img.imageUrl)}
            style={{
              "--color1": color1,
              "--color2": color2,
            }}
          >
            <img src={img.imageUrl} alt={img.name} />
            {/* New Span for ID */}
            <span className="card-id">{img.id}</span>
          </div>
        );
      })}
      {lightbox.open && (
        <div
          className="lightbox"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={closeLightbox}
        >
          <img
            src={lightbox.src}
            alt="Enlarged"
            className="lightbox-img"
            style={{ maxWidth: "90%", maxHeight: "90%" }}
          />
        </div>
      )}
    </div>
  );
}

export default ImageRow;
