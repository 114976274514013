import React, { createContext, useState, useEffect } from "react";

const themes = ["dark", "light", "retro"];

export const ThemeContext = createContext({
  currentTheme: "dark", // Set default to 'dark'
  toggleTheme: () => {},
  setTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  // Initialize theme state based on localStorage or default to dark mode
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem("currentTheme");
    if (savedTheme && themes.includes(savedTheme)) {
      return savedTheme;
    } else {
      // Default to dark mode
      return "dark";
    }
  });

  // Effect to apply the theme class to the body and save to localStorage
  useEffect(() => {
    // Remove all existing theme classes
    themes.forEach((theme) => {
      document.body.classList.remove(`${theme}-mode`);
    });

    // Add the current theme class
    document.body.classList.add(`${currentTheme}-mode`);

    // Save to localStorage
    localStorage.setItem("currentTheme", currentTheme);
  }, [currentTheme]);

  // Toggle theme function to cycle through themes
  const toggleTheme = () => {
    const currentIndex = themes.indexOf(currentTheme);
    const nextIndex = (currentIndex + 1) % themes.length;
    setCurrentTheme(themes[nextIndex]);
  };

  const setTheme = (theme) => {
    if (themes.includes(theme)) {
      setCurrentTheme(theme);
    }
  };

  // Provide the theme state and toggle functions to children
  return (
    <ThemeContext.Provider value={{ currentTheme, toggleTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
