import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import GeneralistBall from "../../assets/GeneralistBall.avif";
import PhysicalBall from "../../assets/PhysicalBall.avif";
import WindBall from "../../assets/WindBall.avif";
import PoisonBall from "../../assets/PoisonBall.avif";
import DisciplineBall from "../../assets/DisciplineBall.avif";
import RockBall from "../../assets/RockBall.avif";
import BugBall from "../../assets/BugBall.avif";
import TrickyBall from "../../assets/TrickyBall.avif";
import SteelBall from "../../assets/SteelBall.avif";
import FireBall from "../../assets/FireBall.avif";
import WaterBall from "../../assets/WaterBall.avif";
import EntangleBall from "../../assets/EntangleBall.avif";
import LightningBall from "../../assets/LightningBall.avif";
import CerebralBall from "../../assets/PsychicBall.avif";
import IceBall from "../../assets/IceBall.avif";
import NullifyBall from "../../assets/NullifyBall.avif";
import TeamworkBall from "../../assets/TeamworkBall.avif";
import GeneralAudio from "../../assets/audio/General.aac";
import PhysicalAudio from "../../assets/audio/Physical.aac";
import SeparatorAudio from "../../assets/audio/Separator.aac";
import IsolationAudio from "../../assets/audio/Isolation.aac";
import DisciplineAudio from "../../assets/audio/Discipline.aac";
import StonewallAudio from "../../assets/audio/Stonewall.aac";
import PestAudio from "../../assets/audio/Pest.aac";
import TrickyAudio from "../../assets/audio/Tricky.aac";
import SwitcherAudio from "../../assets/audio/Switcher.aac";
import HeatCheckAudio from "../../assets/audio/HeatCheck.aac";
import FlowAudio from "../../assets/audio/Flow.aac";
import EntangleAudio from "../../assets/audio/Entangle.aac";
import HighlightAudio from "../../assets/audio/Highlight.aac";
import CerebralAudio from "../../assets/audio/Cerebral.aac";
import ClutchAudio from "../../assets/audio/Clutch.aac";
import NullifyAudio from "../../assets/audio/Nullify.aac";
import TeamworkAudio from "../../assets/audio/Teamwork.aac";
import "../../App.css";
import { typeColors } from "./TypeContainer.js";
import { typeDescriptions, typeTables } from "./typeData.js"; // Import the data

const audioTracks = [
  { src: GeneralAudio, name: "General Theme", artist: "Gatex & Okaymon" },
  { src: PhysicalAudio, name: "Physical Theme", artist: "Gatex & Okaymon" },
  { src: SeparatorAudio, name: "Separator Theme", artist: "Gatex & Okaymon" },
  { src: IsolationAudio, name: "Isolation Theme", artist: "Gatex & Okaymon" },
  {
    src: DisciplineAudio,
    name: "Discipline Theme",
    artist: "Gatex & Okaymon",
  },
  { src: StonewallAudio, name: "Stonewall Theme", artist: "Gatex & Okaymon" },
  { src: PestAudio, name: "Pest Theme", artist: "Gatex & Okaymon" },
  { src: TrickyAudio, name: "Tricky Theme", artist: "Gatex & Okaymon" },
  { src: SwitcherAudio, name: "Switcher Theme", artist: "Gatex & Okaymon" },
  { src: HeatCheckAudio, name: "Heat Check Theme", artist: "Gatex & Okaymon" },
  { src: FlowAudio, name: "Flow Theme", artist: "Gatex & Okaymon" },
  { src: EntangleAudio, name: "Entangle Theme", artist: "Gatex & Okaymon" },
  { src: HighlightAudio, name: "Highlight Theme", artist: "Gatex & Okaymon" },
  { src: CerebralAudio, name: "Cerebral Theme", artist: "Gatex & Okaymon" },
  { src: ClutchAudio, name: "Clutch Theme", artist: "Gatex & Okaymon" },
  { src: NullifyAudio, name: "Nullify Theme", artist: "Gatex & Okaymon" },
  { src: TeamworkAudio, name: "Teamwork Theme", artist: "Gatex & Okaymon" },
];

const firstRowImages = [
  GeneralistBall,
  PhysicalBall,
  WindBall,
  PoisonBall,
  DisciplineBall,
  RockBall,
  BugBall,
  TrickyBall,
];

const secondRowImages = [
  SteelBall,
  FireBall,
  WaterBall,
  EntangleBall,
  LightningBall,
  CerebralBall,
  IceBall,
  NullifyBall,
  TeamworkBall,
];

const Types = () => {
  const [selectedType, setSelectedType] = useState(1);
  const [headerColor, setHeaderColor] = useState(
    typeColors[1]?.color || "linear-gradient(#fff, #fff)"
  );
  const [selectedTab, setSelectedTab] = useState(1);

  const audioRef = useRef(null);
  const progressRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);

  const currentTrack = audioTracks[selectedType - 1];

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.log("Auto-play prevented:", error);
          });
      }
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
      audioRef.current.muted = isMuted;
    }
  }, [volume, isMuted]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
  }, [selectedType]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setProgress(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleProgressClick = (e) => {
    if (audioRef.current && duration && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const newTime = (clickX / rect.width) * duration;
      audioRef.current.currentTime = newTime;
      setProgress(newTime);
    }
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  useEffect(() => {}, [selectedType]);
  const handleImageClick = (index) => {
    setSelectedType(index + 1);
    setHeaderColor(
      typeColors[index + 1]?.color || "linear-gradient(#fff, #fff)"
    );
  };
  const renderContent = () => {
    if (selectedTab === 1) {
      return typeDescriptions[selectedType - 1].map((line, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
      ));
    } else if (selectedTab === 2) {
      const typeText = typeColors[selectedType]?.text || `Type ${selectedType}`;
      const headerBackgroundColor = typeColors[selectedType]?.colorC || "#444";
      const rowBackgroundColor = typeColors[selectedType]?.colorB || "#2e2e2e";
      const rowTextColor = typeColors[selectedType]?.colorD || "#fff";

      const filteredOffensiveRows = typeTables[selectedType - 1].filter(
        (row) => row.header && row.header.trim() !== ""
      );
      const filteredDefensiveRows = typeTables[selectedType - 1].filter(
        (row) => row.value && row.value.trim() !== ""
      );

      return (
        <div className="table-container">
          <div
            className="offensive-table-container"
            style={{
              backgroundColor: rowTextColor,
              borderColor: rowTextColor,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: headerBackgroundColor,
                      borderColor: headerBackgroundColor,
                    }}
                  >
                    {`${typeText} Off. Indicators`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredOffensiveRows.map((row, index) => (
                  <tr
                    key={`offensive-${index}`}
                    style={{
                      borderColor: headerBackgroundColor,
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: rowBackgroundColor,
                        borderColor: headerBackgroundColor,
                      }}
                      dangerouslySetInnerHTML={{ __html: row.header }}
                    />
                  </tr>
                ))}
              </tbody>
              <tr></tr>
            </table>
          </div>
          <div
            className="defensive-table-container"
            style={{
              backgroundColor: rowTextColor,
              borderColor: rowTextColor,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: headerBackgroundColor,
                      borderColor: headerBackgroundColor,
                    }}
                  >
                    {`${typeText} Def. Indicators`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredDefensiveRows.map((row, index) => (
                  <tr
                    key={`defensive-${index}`}
                    style={{
                      borderColor: headerBackgroundColor,
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: rowBackgroundColor,
                        borderColor: headerBackgroundColor,
                      }}
                      dangerouslySetInnerHTML={{ __html: row.value }}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setHeaderColor(
      typeColors[selectedType]?.color || "linear-gradient(#fff, #fff)"
    );
  }, [selectedType]);
  const rowBackgroundColor = typeColors[selectedType]?.colorB || "#2e2e2e";
  /*const rowTextColor = typeColors[selectedType]?.colorD || "#fff";*/
  const headerBackgroundColor = typeColors[selectedType]?.colorC || "#444";

  return (
    <div className="container">
      <div
        className="type-rows"
        style={{ border: `2px solid ${headerBackgroundColor}` }}
      >
        <div className="row">
          {firstRowImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Type ${index + 1}`}
              className={`type-image ${
                selectedType === index + 1 ? "active" : ""
              }`}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
        <div className="row">
          {secondRowImages.map((image, index) => (
            <img
              key={index + 8}
              src={image}
              alt={`Type ${index + 9}`}
              className={`type-image ${
                selectedType === index + 9 ? "active" : ""
              }`}
              onClick={() => handleImageClick(index + 8)}
            />
          ))}
        </div>
      </div>
      <div className="type-description-container">
        <div
          className="type-header"
          style={{
            background: headerColor,
            border: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <h2>{typeColors[selectedType]?.text || `Type ${selectedType}`}</h2>
        </div>
        <div
          className="tabs"
          style={{
            borderLeft: `2px solid ${headerBackgroundColor}`,
            borderRight: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <div
            className={`tab ${selectedTab === 1 ? "active" : ""}`}
            onClick={() => setSelectedTab(1)}
            style={{
              background:
                selectedTab === 1 ? rowBackgroundColor : headerBackgroundColor,
            }}
          >
            Type Description
          </div>
          <div
            className={`tab ${selectedTab === 2 ? "active" : ""}`}
            onClick={() => setSelectedTab(2)}
            style={{
              background:
                selectedTab === 2 ? rowBackgroundColor : headerBackgroundColor,
            }}
          >
            Offensive / Defensive Indicators
          </div>
        </div>
        <div
          className="type-description"
          style={{
            border: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <div className="descriptionCont">
            <div
              className="audio-player"
              style={{ backgroundColor: headerColor }}
            >
              <audio
                ref={audioRef}
                src={currentTrack.src}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={() => setIsPlaying(false)}
              />

              <div className="track-info">
                <p className="track-name">{currentTrack.name}</p>
                <p className="track-artist">{currentTrack.artist}</p>
              </div>

              <div className="controls-container">
                <button
                  className="play-button"
                  onClick={handlePlayPause}
                  aria-label={isPlaying ? "Pause" : "Play"}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </button>

                <div
                  className="progress-container"
                  onClick={handleProgressClick}
                  ref={progressRef}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: duration
                        ? `${(progress / duration) * 100}%`
                        : "0%",
                    }}
                  />
                </div>

                <div className="volume-container">
                  <button
                    className="volume-button"
                    onClick={toggleMute}
                    aria-label={isMuted ? "Unmute" : "Mute"}
                  >
                    <FontAwesomeIcon
                      icon={isMuted || volume === 0 ? faVolumeMute : faVolumeUp}
                    />
                  </button>
                  <input
                    className="volume-slider"
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={(e) => setVolume(parseFloat(e.target.value))}
                  />
                </div>
              </div>

              <div className="time-info">
                <span className="current-time">{formatTime(progress)}</span>
              </div>
            </div>

            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Types;
