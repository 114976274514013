import React from "react";
import "../../App.css";
import "./ContactForm.css";
import { useState } from "react";
import Kofi from "../../assets/Kofi.png";
import Twitter from "../../assets/Twitter.png";
import Instagram from "../../assets/Instagram.png";
import YT from "../../assets/Youtube.png";
import Playlist from "../../assets/Playlist.png";
import Okbb from "../../assets/Okbb.png";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDetails = {
      access_key: "aad0b0ec-97eb-4071-98fc-7ffbf52e2f65", // replace with your Web3Forms access key
      ...formData,
    };

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formDetails),
    });

    const result = await response.json();

    if (result.success) {
      setSuccessMessage("Your message has been sent successfully.");
      setErrorMessage("");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } else {
      setErrorMessage("Something went wrong, please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="container">
      <div className="contact-form-wrapper">
        <div className="contact-form-container">
          <form id="contact-form" onSubmit={handleSubmit}>
            <h3>Contact Us</h3>

            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div className="input-container">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <button type="submit" id="submit-btn">
              Send
            </button>
          </form>
        </div>
        <div className="icon-container">
          <div className="icon">
            <a
              href="https://twitter.com/okayed"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
            <p>Twitter</p>
          </div>

          <div className="icon">
            <a
              href="https://instagram.com/passer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="Instagram" />
            </a>
            <p>Instagram</p>
          </div>

          <div className="icon">
            <a
              href="https://youtube.com/dunks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={YT} alt="YouTube" />
            </a>
            <p>YouTube</p>
          </div>

          <div className="icon">
            <a
              href="https://www.youtube.com/playlist?list=PLINnzz6ZGn8kzLYNu2ZbpFwgSkAporawe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Playlist} alt="Music" />
            </a>
            <p>Music</p>
          </div>

          <div className="icon">
            <a
              href="https://okaybasketball.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Okbb} alt="Webpage" />
            </a>
            <p>Webpage</p>
          </div>

          <div className="icon">
            <a
              href="https://ko-fi.com/okayed"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Kofi} alt="Ko-fi" />
            </a>
            <p>Ko-fi</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
