import React from "react";
import ImageRow from "./ImageRow";
import PlayerStats from "./PlayerStats";
/*import GroupTextBox from "./GroupTextBox"; */
import TitleBar from "./TitleBar";
import TypeContainer, { typeColors } from "../Types/TypeContainer";
import "../../App.css";
import instagramLogo from "../../assets/iglogo.svg";

const GroupContainer = ({ groupData }) => (
  <div className="group-container">
    <div className="title-row">
      {groupData.players.map((player) => (
        <TitleBar
          key={player.id}
          title={player.name}
          comp={player.comp}
          color1={player.color1}
          color2={player.color2}
        />
      ))}
    </div>
    <ImageRow images={groupData.players} />
    <div className="type-row">
      {groupData.players.map((player) => (
        <TypeContainer key={player.id} types={[player.type1, player.type2]} />
      ))}
    </div>
    <div className="player-stats-row">
      {groupData.players.map((player) => {
        const primaryType = player.type1;
        const secondaryType = player.type2;
        const primaryTypeColor = typeColors[primaryType]?.color;
        const primaryTypeColorB = typeColors[primaryType]?.colorB;
        const primaryTypeColorC = typeColors[primaryType]?.colorC;
        const primaryTypeColorD = typeColors[primaryType]?.colorD;
        const secondaryTypeColorB = secondaryType
          ? typeColors[secondaryType]?.colorC
          : null;
        const borderColor = secondaryTypeColorB || primaryTypeColorD;

        return (
          <PlayerStats
            key={player.id}
            stats={player.stats}
            typeColor={primaryTypeColor}
            typeColorB={primaryTypeColorB}
            typeColorC={primaryTypeColorC}
            typeColorD={primaryTypeColorD}
            borderColor={borderColor}
          />
        );
      })}
    </div>
    {/*<GroupTextBox text={groupData.groupText} />*/}
    <div className="groupFooter">
      <a href={groupData.footerLink} target="_blank" rel="noopener noreferrer">
        {groupData.illImage && (
          <img
            src={groupData.illImage}
            alt="Group Illustration"
            className="group-illustration"
          />
        )}
        <img src={instagramLogo} alt="Instagram" className="instagram-logo" />
        {groupData.footerText}
      </a>
    </div>
  </div>
);

export default GroupContainer;
