import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import "./ThemeSwitch.css";
import Moon from "../../assets/Moon.png";
import Sun from "../../assets/Sun.png";
import Retro from "../../assets/Retro.png";

const ThemeSwitch = () => {
  const { currentTheme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = (theme) => {
    setTheme(theme);
  };

  return (
    <div className="theme-switch-container">
      {/* defaulting dark theme */}
      <div
        className="theme-option dark-option"
        onClick={() => handleThemeChange("dark")}
      >
        <img
          src={Moon}
          alt="Dark Theme"
          className={currentTheme === "dark" ? "selected" : ""}
        />
      </div>
      <div
        className="theme-option light-option"
        onClick={() => handleThemeChange("light")}
      >
        <img
          src={Sun}
          alt="Light Theme"
          className={currentTheme === "light" ? "selected" : ""}
        />
      </div>
      <div
        className="theme-option retro-option"
        onClick={() => handleThemeChange("retro")}
      >
        <img
          src={Retro}
          alt="Retro Theme"
          className={currentTheme === "retro" ? "selected" : ""}
        />
      </div>
    </div>
  );
};

export default ThemeSwitch;
