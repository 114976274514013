import React from "react";
import "../../App.css";

const TitleBar = ({ title, comp }) => {
  const getFontSize = (text, isTitle) => {
    if (isTitle) {
      if (text.length > 20) return "27px";
      return "33px";
    } else {
      return "18px";
    }
  };

  return (
    <div className="title-container">
      {/* Primary Title */}
      <div className="title-bar">
        <span
          className="title-text primary-title"
          style={{
            fontSize: getFontSize(title, true),
          }}
        >
          {title}
        </span>
      </div>

      {/* Secondary Title, renders if comp */}
      {comp && (
        <div className="title-bar">
          <span
            className="title-text secondary-title"
            style={{
              fontSize: getFontSize(comp, false),
            }}
          >
            {comp}
          </span>
        </div>
      )}
    </div>
  );
};

export default TitleBar;
