import React from "react";

const PlayerStats = ({
  stats,
  typeColor,
  typeColorB,
  typeColorC,
  typeColorD,
  borderColor,
}) => {
  const statKeys = Object.keys(stats);

  const statSum = statKeys.reduce((sum, key) => sum + stats[key], 0);

  const statColors = {
    HP: {
      containerColor: "#98f372",
      barColor: "#7cd657",
      borColor: "#529237",
    },
    "INS OFF": {
      containerColor: "#f3e272",
      borColor: "#9B8510",
    },
    "INS DEF": {
      containerColor: "#F09A65",
      borColor: "#97410C",
    },
    "PER OFF": {
      containerColor: "#E46CCA",
      barColor: "#c73882",
      borColor: "#77224e",
    },
    "PER DEF": {
      containerColor: "#6153B7",
      barColor: "#4e3cc3",
      borColor: "#2f2475",
    },
    SPEED: {
      containerColor: "#4d9be3",
      barColor: "#457cad",
      borColor: "#2d5f8c",
    },
  };

  return (
    <div
      className="stats-container"
      style={{ background: typeColorC, borderColor: borderColor }}
    >
      <div
        className="stats-label"
        style={{ background: typeColorB, borderColor: typeColorC }}
      >
        STATS
      </div>
      {statKeys.map((key) => (
        <div key={key} className="stat-row" style={{ borderColor: typeColorC }}>
          <div
            className="stat-label-container"
            style={{
              backgroundColor: statColors[key]?.containerColor,
              borderColor: typeColorC,
            }}
          >
            <span className="stat-label">{key}:</span>
            <span className="stat-value">{stats[key]}</span>
          </div>
          <div
            className="stat-bar-container"
            style={{ borderColor: typeColorB }}
          >
            <div
              className="stat-bar"
              style={{ backgroundColor: statColors[key]?.containerColor }}
            >
              <div
                className="stat-fill"
                style={{
                  width: `${(stats[key] / 200) * 100}%`,
                  backgroundColor: statColors[key]?.barColor,
                  borderColor: statColors[key]?.borColor,
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <div
        className="stat-row sum-row"
        style={{ background: typeColorB, borderColor: typeColorC }}
      >
        <div
          className="stat-label-container"
          style={{ background: typeColorB, borderColor: typeColorC }} // can set a different color if needed
        >
          <span className="stat-label">Total:</span>
          <span className="stat-value">{statSum}</span>
        </div>
      </div>
    </div>
  );
};

export default PlayerStats;
