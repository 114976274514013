export const typeDescriptions = [
  [
    "<span style='color: #a8a878; font-weight:bold'>Generalists</span> are players who may lack a distinct or specialized talent but are competent, if not great, at a wide variety of position related skills. Unlike other types that leverage highly specific strengths to their advantage (which may also cause a distinct disadvantage), a Generalist's value comes from their ability to consistently contribute in nearly any situation. While the lack of specific stylistic advantage may mean their ability to impose themselves on another player and take over a game is lessened, their \"well-roundedness\" and consistency gives them what is perhaps the highest impact floor of all types.",

    "That trade-off of overt strengths results in the Generalist having very few weaknesses in return, allowing them to be an effective tool in just about any role and able to bolster every lineup they’re used in. This doesn't mean Generalists exist solely to be glue guys who just fill in the gaps for their team though. While many Generalists can be described as jack-of-all-trades, masters of none, the best of this type can surpass that  label and become more of an ace-of-all-trades, as it were.",

    "On account of their skill set, Generalists tend to favor a style of play that  focuses on not deviating from their abilities or trying to do more than they should. Rarely will you see a General type taking big risks or trying to do “too much”, instead they will adhere to their team’s game plan, back out of something if it seems out of their comfort zone, and keep things moving as smoothly as possible.",

    "On offense, Generalists will be able to find a spot in just about any scheme or lineup composition thanks to their average or above average shooting, finishing, and playmaking skills. While they create no overt mismatches for other types, their ability to provide a little bit of everything (or a lot of it) with no significant drawback allows General types to be effective against just about every matchup they'll encounter.",

    "The exception to that effectiveness is when Generalists come into contact with potent defensive specialists like Stonewall and Switcher types. Since Generalists do not tend to have a clear advantage when scoring around the rim, hyper-specialized Stonewall types who excel at blocking off the hoop are very effective at deterring the Generalist. Since the Generalist can’t consistently get through an evenly matched Stonewall player, this turns them into rather one dimensional scorers as they’re locked out of the paint.",

    "While Switcher types won't take away a specific skill from Generalists, they are able to be excellent on-ball defenders against them no matter where they go. Since Generalists are unlikely to possess the ability to break or burst through a defense with significant speed or strength, Switchers can slide with them and provide a steel blanket coverage that reduces the overall effectiveness of all of the Generalist's skills.",

    "The biggest issue for offensive Generalists is when dealing with Tricky types.While Generalists do not tend to be able to be goaded into risky plays, a defensive Tricky type will be getting in their way, trying to take charges and trying to force Generalists into having to take on responsibilities that are out of their comfort zone. In trying their best to not be baited, Generalists will often just get the ball to another player as quickly as possible when guarded by a Tricky type, making them a relative non-factor on offense.",

    "Oddly enough the opposite proves to be true on defense. Since offensive Tricky types excel in playing mind games and attempting to leverage a player's specialty against them, they run into an issue with Generalists who aren't thinking about how to use their own specific skill to defend them, but instead are sticking to well-measured fundamentals and their team’s game plan. While on offense the Generalist has to think and do more as an individual outside of a scheme, on defense they can focus on what their role is inside a more team-oriented aspect of the game, and allow that to guide their actions. Good coaching and scheming can reroute the General type around any nasty plots of a Tricky type and  leads to Generalists being outright talented defenders against them. That unwillingness to play outside of their skill set while still being set up to excel in the defensive scheme makes them great against Tricky types while remaining neutrally effective on defense against all but one other offensive type.",

    "The one exception to a Generalist’s defensive approach are Physical players, who leverage their aggression, strength, and high effort level to dislodge or disrupt the General type and cast them aside with little resistance. Since Generalists do not want to get in a game of needing to push back ot stand up to the Physical type, nor will they have the speed or style to disrupt them or pick at their shortcomings, the Generalist will opt to get out of the way and avoid the confrontation, leading to a Physical type with a head of steam charging down the lane and looking at a 2-on-1 opportunity.",

    "<span style='color: #a8a878; font-weight:bold'>Historical Offensive Generalist Types:</span> Clyde Drexler, Paul Pierce, Mickey Johnson, Vlade Divac.",

    "<span style='color: #a8a878; font-weight:bold'>Historical Defensive Generalist Types:</span> Dave DeBusschere, Paul Pressey, P.J. Brown, Trevor Ariza.",
  ],
  [
    "<span style='color: #c03028; font-weight:bold'>Physical</span> types are experts at leveraging their strength and raw athleticism into an aggressive playstyle that allows them to contain, overwhelm, and disperse other players. While they have a reputation as being injudicious or reckless players, it would be a mistake to consider them an unthinking out-of-control whirlwind of impulsive reactions and decisions. Their confrontational style of play is a calculated recklessness that the Physical type has mastered over the course of their career. Each Physical type has developed unique ways to use their outlier athletic abilities to make them an imposing force on the basketball court, the same way each martial art has a unique way of approaching combat.",

    "An offensive Physical type is what you may consider a wall-breaker. They tend to forgo the pleasantries of highly skillful dances around the court, or precise movements off ball to find space in the margins of a defense, instead preferring to smash through defenders by force. This is especially effective when going against slower, less athletic, stiffer players like Stonewall types. While Stonewall types are quite large, Physical players will see them as towers to their wrecking ball, and will dive into the paint to bully them under and into the basket.",

    "Switchers, while quite a bit more elastic than Stonewalls, will still be a bit too stiff to hold up to the Physical type who is going to look to test the limits of that flexibility. While Switchers are quite good at moving down from the perimeter to the post in one motion, Physical types are just a bit faster, a bit stronger, and once they have established themselves down low can relentlessly batter the Switcher out of position.",

    "Physical types are also highly effective against Generalist types, whose risk-averse defensive approach leaves them vulnerable to strong, decisive offensive actions. Generalists are looking to use their wide range of skills to be able to have a high floor in most matchups as it allows them to cover a many different scenarios, but this is significantly less effective against Physical types who are forcing one advantageous type of action, over, and over, and over.",

    "That kind of dominating scoring style allows Physical players to even shatter the defense of Clutch players at the end of games. While Clutch types excel in high-leverage, high-risk situations, their reaction based help defense will not be quick or strong enough to be impactful, and all the focus and perfect timing in the world cannot slow down a Physical type with a full head of steam.",

    "Physical types can be bothered by quicker types like Separators however, who are able to fly around the perimeter or meet Physical types in the air where they have a clear advantage. While Physical types are freight trains down the lane, the Separator's ability to navigate space and find the right angles to cut them off can end up forcing them onto a different track. They have the same effect in the air, as they'll be able to sky to the rim in such a way that the Physical type will have to do their best to contort their body for a finish, or risk having the ball plucked from them at the apex of their jump.",

    "Physical types are also much less effective as scorers against Isolation types, as they operate very well in tight spaces and have plenty of counter-play to close quarters fighting. Physical types will find maneuvering out of or around Isolationists' constrictive defense quite difficult given that they tend to lack a particularly good handle and cannot outmatch the footwork of Isolation types in order to get around them. Isolation players ability to play up tight on an offensive player without fouling also creates a precarious situation for Physical types who rely on making big powerful movements towards the rim, as they are highly likely to bowl over an Isolationist and be called for an offensive foul if they simply try and run through them.",

    "High activity Pest types will greatly bother Physical types as well, as these incredibly agile and avoidant players will prey on the Physical types' poor ball handling and difficulty in handling double teams. Pest types will be drawn to Physical types whenever they have the ball in their hand, looking to poke and prod at it as helpers before the Physical type can start their move towards the basket. Even if that does not cause a turnover, that kind of incessant buzzing around the ball will cause the Physical player to delay putting it on the floor and play cautiously - something they can't afford to do most of the time. The Physical types' propensity for offensive fouls due to over-aggression and a lack of maneuverability are taken advantage of by the Pest type as well, who has no problem jumping in front of them to take a charge, no matter if they're splattered on the floor afterwards.",

    "Cerebral and Teamwork types have a similar strength on defense against Physical types, as they easily diagnose their often limited offensive repertoire and stay a step ahead of them as individual defenders or by directing their team's defense. While Physical types are certainly not dumb, they do not have the nuance of many other types as offensive players. Because they are so effective with their weapon of choice, this becomes rather predictable, and both the Cerebral and Teamwork type can diagnose exactly how to attack them on the defensive end.",

    "However their biggest weakness on offense comes at the hands of Tricky types, who will consistently bait the Physical types aggression against them. Physical types are prone to becoming complete and total turnover machines in the hand sof a Tricky type, who will set trap after trap to get them to move into a position where they get trapped, cough up the ball, be called for an illegal screen, pick up a charging foul, and every other negative play you can imagine. Worse yet is the Tricky types penchant for mind games, as they will spend the entire game goading the oft-temperamental Physical type into verbal spats that result in the Physical type becoming much more worked up than the Tricky type, much more prone to involving their hands in any type of fight, and getting a Rasheed Wallace-esque rate of technicals put on them. Because Tricky types are so problematic for Physical types in just about any way, including their ability to get them kicked out of games entirely, most teams will look to keep their Physical player as far away from a Tricky type as possible.",

    "On defense Physical types remain excellent against Stonewall types. While big and tall themselves, Stonewalls really have a tough time finding a way to push through the staunch defense of a Physical player. In pick-and-rolls the Stonewall's rolling is going to get bumped and sent off course by the Physical type constantly. If a Stonewall type is going to post up, they will find that the Physical player's lower center of gravity and extremely strong base makes them a near immovable object. Their best bet is to try and get some lob opportunities at the rim, but even then, Physical defenders have a great ability to launch up in the air and contest these attempts as the primary rim defender or as a help defender from afar. This is even a problem on the boards for Stonewall types, as Physical players are absolute monsters on the defensive glass and can limit the amount of orebs available to the Stonewall type and their ability to get easy putbacks.",

    "Switcher types continue to struggle against Physical types for the same fundamental reasons. While quick and strong themselves, they're simply a bit less so than the Physical type and their two-pronged game gives them very few chances to break through a Physical player's defense. They will find it extremely difficult to gain any positive ground on offense whatsoever, as their ability to be back door cutters is sealed off by the bumper car in the paint that a Physical player becomes, and even struggle to deal with the positional fighting to come up and set screens.",

    "Physical types are able to flip the script on offensive Pest types, as they will find it quite difficult to find a way to get past, through, or over a Physical player who simply stands their ground and moves their feet. While Pests can harangue a Physical player when they have the ball in their hand, once the Pest is on offense, the Physical player is able to use their athleticism to it's full extent. A Physical type will look to stay in front of a Pest and push back against them, little by little cutting off driving lanes and access areas until they can bounce them into a corner where they have nowhere to go.",

    "Where Physical types start to struggle as defenders is against extreme off-ball movement types like, once again, Separators. These players will run the Physical player into screen after screen, as the Physical type is either not quite agile enough to navigate the slalom course, or in order to do so they have to expend so much energy that they're unable to play a good amount of minutes. Either through lack of maneuverability or the setting in of fatigue, this results in a high chance of defensive fouls against the Physical type as attempt to chase the Separator all over the court. This may also lead to risky closeouts on jump shots, or ill-advised rim contests from the dunking-type Separators who can take flight at any moment.",

    "They also remain less effective against Cerebral and Teamwork types. While Physical types can blow plays up all over the floor with their aggressive help defense, both the Teamwork and Cerebral types can use that against a Physical type by baiting their ball watching and aggression like a matador with a bull, pulling them to areas of the court and out of plays with ease. This same strategy can work against the Physical types' on-ball defense as well, as these types can engage in a bit of fighting with the Physical type and pull them out of range of helping against another teammate, like a Stonewall for example, so they can set up a play for said teammate with little worry.",

    "<span style='color: #c03028; font-weight:bold'>Historical Offensive Physical Types:</span> Shaquille O'Neal, Bob Lanier, Cedric Maxwell, Darryl Dawkins.",

    "<span style='color: #c03028; font-weight:bold'>Historical Defensive Physical Types:</span> Ben Wallace, Ron Artest, Vernon Maxwell, Dale Davis.",
  ],
  [
    "<span style='color: #a890f0; font-weight:bold'>Separators</span> are a particularly unique type where all players that exist in the Separator category sit along a slash-splash spectrum. On one end you have players who are three-point specialists - generally movement shooters - who rarely venture inside the arc, while on the other you have cutters who swoop into the lane and sky over the tallest of defenders. While these playstyles may seem in total opposition to one another, what brings them together is their use of off-ball movement to create separation from their opponents.",

    "On offense, Separators are especially effective against Physical types, who can be overly aggressive in ball denial on the perimeter and get caught on screens, leading to open three point shots and straight shots to the rim. For similar reasons they are excellent against Pests, who can avoid screens quite well, but will tire out from the sheer amount of ground they have to cover while chasing Separators around the court. Additionally, Pests are very ill equipped to contest the kind of shots Separators take. Entangle types also fare poorly against Separators, as their distance covered on offense and maneuverability allow them to avoid Entanglers who are trying to jam up their flight path.",

    "Where Separators struggle the most on offense is against types who can meet them at their apex, specifically Stonewall and Highlight types. Stonewalls are the prototypical shot blockers and fare well against Separators who peel around a screen and head towards the rim. They have ample time to adjust and time their contest while not having to worry much about a dump off to their man from the Separator, as they are not known for their passing ability. Highlight types succeed in a very similar way, as they can hunt big blocks on the perimeter or at the rim, without having to worry about the common pitfalls of over-committing.  Switchers are also quite effective against Separators, as they are often in a position to wave back the Separator's main defender on a screen and switch onto them seamlessly.",

    "Defensive Separator types are a rare group of elite screen navigators who can disrupt passing lanes with their unrivaled ability to cruise around in space. They remain very effective against Physical players who have a tough time finding clean looks at the rim, as the Separator will either hone in on the ball as it's floated to them, or get a jump on them as they're heading to the rim.",

    "Despite their quickness, Pest types have a very tough time out-operating the Separator. Their fluidity and efficiency of movement in the open court stifles Pests, as the herky-jerky, shifty, probing style of movement the Pest employs isn't effective enough to find entryways against a patrolling Separator type. As such, the Pest's possession often turns into more of a desperate scramble as they burn energy trying to find an opening and are eventually forced into giving up the ball in frustration.",

    "Entangle types find an issue with their lack of speed as well, as their slower paced, foul-seeking playstyle is easily avoided by the nimble Separators. Since Entangle types are not just seeking but anticipating contact with the defender, the Separator will pull chairs to unbalance the Entangler, leading to travels, off-rhythm shot attempts, and bad passes that the Separator will pluck out of the air.",

    "Discipline types find the most difficulty against Separators, as their metered and measured game can be completely disrupted by Separators. They can not only beat the burrowing and cutting Discipline players to their spots, but they will find a ton of success fronting post ups by maneuvering around the offensive player and denying or intercepting entry passes, as is their specialty.",

    "Predictably, they will still struggle greatly with the Stonewall types, who despite being slower than them, tend to be much larger and stronger and will simply power through a contesting Separator at the rim.",

    "They also struggle with Highlight and Clutch types. Not only are both types stronger at the rim if the Separator dares meet them up high, but they also have the  ability to nail a shot before the Separator can glide over to contest it - Highlight types due to their peerless speed, and Clutch types because of their killer timing.",

    "<span style='color: #a890f0; font-weight:bold'>Historical Offensive Separator Types:</span> <span style ='font-weight:bold'>Reggie Miller, Rip Hamilton, Gordon Hayward, Channing Frye.</span>",

    "<span style='color: #a890f0; font-weight:bold'>Historical Defensive Separator Types:</span> <span style ='font-weight:bold'>Andrei Kirilenko, Michael Ray Richardson, Eddie Jones, Brian Taylor.</span>",
  ],
  [
    "<span style='color: #a040a0; font-weight:bold'>Isolation</span> types specialize in close quarters, one-on-one scenarios where they can chip away at their opponents with a wide variety of counters, counters to counters, and counters to those counter's counters. Since their effectiveness is dependent on their ability to focus on a singular opponent, Isolationist's initial efforts will go towards pulling players away from their team so they can hone in on them with no interference.",

    "On offense, Isolation types are deadly scorers who look to break their defenders down with destabilizing movements and demoralizing shot making. They are highly effective against Entangle types who look to bait them into offensive fouls, as their superior ball handling and footwork allow them to evade the tangle-up without needing to push off or bully through them. Isolation types will often wave off a screen from a teammate if Entangling types are involved to take away the repeated potential for moving screens from the Entangler, as they are looking to junk up pick-and-roll actions. This is similarly discouraging for Teamwork types who specialize in being able to read multi-player interactions as defenders, calling out coverages, directing teammates, and all sorts of other things that become less impactful when they're off on an island with the Isolationist.",

    "As exceptional as Isolationist's scoring is against two of the most pervasive and effective defensive types, they are not without some real drawbacks. First and foremost, they tend to struggle when matching up with their typing counterpart. Since Isolationists tend to skew more towards volume rather than efficiency, defensive Isolation types will be able to greatly limit the value of that style of scoring as the offensive player will have to expend a great deal of energy and use a whole lot of time to get a decent look. Discipline types are similarly methodical in their defensive approach and their ability to stay grounded allows them to not fall for pump fakes and contest tough shots without fouling.",

    "Tricky types are also an issue for Isolationists, as their attempts to throw off the balance of defenders are rather transparent and ineffective to the masters of deception. All that one-on-one time also allows the Tricky type to get a lot of talking in, giving them more time than usual to observe the Isolationist and see what gets them out of their game.",

    "They also have a tough time with Stonewalls, as Isolationists will lack the type of burst needed to blow past a Stonewall type in drop coverage, and will be stifled by help defenders who can swarm the Isolationist and offer a much needed double in the midrange. The defenders they find the most trouble with though are Switcher types, as they provide a very high level of on-ball defense in the most common scoring zones for Isolationists: the high post, mid post, and baseline. Switchers can also easily decline an Isolationist's attempt to find a mismatch with screens, as they can fight up or over screens as an on-ball defender, or call for a switch and affix themselves to the Isolationist as the screener's defender.",

    "Defensive Isolation types will look to attach to their opponent like a thick fog, suffocating them with on-ball pressure, good screen navigation, and avoiding the motion of offenses that look to peel them off of their target. As previously mentioned, this results in defensive Isolation types being an excellent option to tag offensive Isolation types, as that kind of war of attrition will often result in high effort, low output nights for the scorer.",

    "Isolationists tend to be defensive menaces for most players that require high amounts of effort to score, whether that's the probing speed of Pest types, or players who look to bully their way into scoring like Physical and Entangle types. Pests struggle to find advantages, as their speed and quickness can be neutralized by the blanket coverage of Isolationists who stick to them like glue. Physical players' lack of ball handling will make it very difficult to maneuver away from the Isolationists coverage, resulting in them picking up their dribble and coughing up the ball far too often. Entangle types see a major part of their scoring arsenal taken away, as their ability to create contact by wrapping up players who get close to them is largely avoided by Isolationists, who can force them into tough shots rather than letting them parade to the line.",

    "Teamwork types continue to have an issue with defensive Isolationists, who will keep them locked off from the play as long as they can, resulting in much slower set plays, long delays in getting into PnR action, and a decreased ability for the Teamwork type to act as a connective passer when they do not have the ball. However, where Teamwork types have struggled, Cerebral types have found a way to flourish by using the Isolation player's desire to separate an opponent against them. Not only do Cerebral players operate well as playmakers and plate-setters in these isolation situations, but they will often find a way to get the Isolationist switched onto them as a way to drawing them away from a more important assignment. This allows the Cerebral type to get the ball over to the scorer who now has a much more advantageous matchup.",

    "Additionally, <span style='color: #d4a82f;'>Discipline</span> types unflustered, patient approach to finding high efficiency shots for themselves is very effective against Isolationists. If an Isolation player tries to limit them in getting to their spot, they will kick the ball out, reset the play, and re-post as many times as they need to get where they want to be. The same goes for cutters and Discipline type point guards, who will reset a screen as many times as needed to get the Isolationist off of them and wear them out.",

    "<span style='color: #a040a0; font-weight:bold'>Historical Offensive Isolation Types:</span> <span style='font-weight:bold'>Kobe Bryant, Bernard King, Joe Johnson, Jamal Crawford.</span>",

    "<span style='color: #a040a0; font-weight:bold'>Historical Defensive Isolation Types:</span> <span style='font-weight:bold'>Sidney Moncrief, Doug Christie, Avery Bradley, Corey Brewer.</span>",
  ],
  [
    "Despite not having the flexibility of some other types, <span style='color: #d4a82f; font-weight:bold'>Discipline</span> players have a true mastery of the fundamental principles of the game, making for a highly efficient and effective playstyle on both ends. Their error-free, grounded approach is near impossible to get them out of, as they are anti-reactive and purposeful in nature, looking to treat each possession as a race where they are the tortoise to the opponent's hare.",
    "On offense, Discipline types look to grind out possessions until they've forced their opponent into making a mistake. Against Switcher, and Isolation types, Discipline players employ this offensive philosophy perfectly. Whether that's bullying them out of position on a post up little by little, or running the same pick-and-roll action every trip down the court, Discipline players can erode these two types by applying pressure to the same spot, over, and over, and over.",
    "Discipline types can pose a real problem for Stonewall types as well, whose focus on rim protection and help defense are not particularly effective against a plodding-and-plotting scorer. If a Discipline type gets put into a one-on-one situation with a Stonewall, their landlocked approach will allow them to easily back that player down until they're under the rim and unable to contest effectively. As a perimeter ball handler in the pick-and-roll, the Discipline type will push and pull a Stonewall defender all over the court, exhausting them and keeping them well out of position to get back to the rim for a contest.",

    "Against Heat Check and Highlight types, Discipline types find a significant advantage from their risk-free style of play. Both of those defensive types are looking to use explosive bursts of athleticism or agility to make big time plays: blocks, steals, timely traps in the half-court, all of which the Discipline type is inherently avoidant of given their slow, stable, metered playstyle. Discipline types' can even post up in Heat Check player's hot zones and be largely unaffected.",

    "While Discipline types do not have too many defenders they struggle with, Pest and Entangle types have found success by getting into the Discipline player's space. Both types can burrow into the Disciplined type as defenders, taking root in a position and playing very close without fouling. This allows them to delay possessions as the Discipline type tries to avoid an offensive foul by resetting the play. Each time that happens, the Pest or Entangle type can push up a little bit more and try to carve more space out for themselves, prolonging the offensive possession for huge chunks of the shot clock and giving them additional chances to get an offensive foul call against the Discipline player.",

    "Offensive Discipline types have the most trouble, by far, with Separators. They are experts at seamlessly navigating screens and sticking onto their matchup, making them exceptional defenders in the pick-and-roll against score-second guards and allowing them to recover immediately even if displaced by a rolling Discipline type. Their maneuverability as defenders also allows them to pester posting Discipline types by swooping around to front them, delaying the entry pass, tapping the ball away and resetting the possession, or even intercepting it and taking off in transition.",

    "Defensively, Discipline types continue to have an advantage against against Isolation and Stonewall types. Isolationists face a significant challenge in trying to go one-on-one with Discipline types, as they rely on a great deal of fancy footwork, getting the defender off balance, and generating good looks from tough positions. Discipline players' main strength on defense is their judiciousness, making them very hard to shake for Isolationists whose pump fakes and crossovers will not get a nibble, let alone a bite.",

    "Stonewall types face the opposite issue, wherein their scoring ability comes from the ability to get to the rim with their size and hammer it over smaller, weaker, or out of position defenders, rather than nuanced movement. Discipline types are not going to fail in coverages against Stonewall types as they're quicker on their feet and can get to every spot before a Stonewall does, making them excellent solid rim deterrents who will eat up space in the paint and contest without fouling.",
    "That deterrent style of play makes it exceptionally difficult for Highlight types to find a way to score, as Discipline types' excellent paint defense can wall off the Highlight type from good looks around the rim, and their measured approach on the perimeter means they will not get their feet caught up in a perimeter defense dance either.",

    "Though they heavily prioritize playing mistake-free basketball, that approach still leaves Discipline players with a few weaknesses. For one, they find it very hard to avoid picking up fouls against Entangle types. Entanglers will use their invasive style of play to work into the Discipline player's body as they attempt to stand their ground, then take shot attempts through them before they can be avoided, nullifying their generally staunch and sound defense and often sending them to the bench early.",
    "They also struggle with high-activity Flow types, who are able to flip between scoring, passing, screening, and acting as decoy all in the same play, causing the Discipline player to lose their solid ground on defense as they float around the court unable to settle into a single coverage.",
    "Clutch types are also excellent at keeping Discipline players off-balance, as the simple, low-risk, and foul averse playstyle of the Discipline defender results in them playing high leverage moments in an avoidant way, rather than the proactive or predictive way needed to shatter a Clutch player's heroics. ",

    "<span style='font-weight:bold'><span style='color: #d4a82f; font-weight:bold'>Historical Offensive Discipline Types:</span> Kevin McHale, Adrian Dantley, Al Jefferson, Luis Scola.</span>",

    "<span style='font-weight:bold'><span style='color: #d4a82f; font-weight:bold'>Historical Defensive Discipline Types:</span> Nate Thurmond, Maurice Cheeks, Derek Harper, Chuck Hayes.</span>",
  ],
  [
    "While <span style='color: #b8a038; font-weight:bold'>Stonewall</span> types include offensive types who can deliver backboard shattering rack-attacks, they are almost always larger, slower, less-flexible defensive types that specialize in protecting the rim. Though they tend to be rather rigid in their playstyle, their hyper specialized focus around the basket makes it near impossible for certain types of players to find success against them down low.",

    "Though rare, the offensive Stonewalls is a devastating scorer in the paint. They will ground even the highest-flying Separators who dare challenge them at the rim, as the sheer difference in size and weight is enough to send them crashing down to earth like Brandon Knight; not to mention the perimeter oriented Separators who are even smaller and will never be physically up to the task of defending around the basket.",

    "Pest types will deal with similar issues due to their size. To the diminutive Pest, a Stonewall with the ball in their hands seems like a lighthouse with a brightly shining bulb. Their lack of handle lures Pests into trying to strip them of the ball, but that will often backfire as the Pest is too small to effectively attack the ball when it's held high. This results in them committing a foul or taking themselves out of position, opening up to a pass easy enough for even the Stonewall to make.",

    "Heat Check and Clutch types will also find it tough to defend against the Stonewall as they both operate in a very bursty style of play, something that's quite tough to do consistently in the extremely high leverage moments around the rim against Stonewalls. With Stonewall players being larger and stronger than most Heat Check and Clutch types, and specializing at scoring in the paint, the chance of those players fouling the Stonewall for an and-one is quite high. Even as helpers, their aggressive nature on defense sees them helping off of more potent offensive options too often, giving the Stonewall a great target to kick it back out to.",

    "If you're looking to topple a Stonewall on offense, it needs to be with types who can stand up to them before they get close to the rim by closing driving paths off, out-muscling them, and stifling their movement. Defensive Physical types are well-suited for this, as they tend to be much stronger, more athletic, and significantly better balance. This allows them to push the Stonewall off the block, out-hustle them for rebounds, and negate back-down attempts. Discipline players are also quite good at preventing the Stonewall player getting close to the rim, as they play a very fundamentally sound style of post defense that the Stonewall player has a tough time breaking through with their rudimentary post moves. Switchers are the last type that can be used to deter Stonewalls, as their ability to swap from handler to roller in the pick-and-roll, staunch on-ball defense, and lateral quickness can blow up the most popular Stonewall offensive actions.",

    "You're much more likely to see Stonewalls as oft-used defensive specialists who excel against a number of potent scoring types. They continue to be strong against the formidable Heat Check type, providing strong rim defense and continual contests around the basket that make it difficult for inside-focused Heat Check players to string together a bunch of makes and get into the zone.",

    "Stonewall types are excellent at defending against cutting and driving Separators who are looking to pierce the lane and throw down a dunk, and even the less mobile catch-and-shoot Separators who are susceptible to a larger player stepping out and contesting their shot due to their lack of off-the-dribble scoring.",

    "Even potent live-dribble scorers like Isolationists have a tough time finding a way to perforate Stonewalls, as their length and timely help defense sees Isolation players having to shoot over towering contests and avoid traps and double teams.",

    "Generalist types see a bit of trouble too, given that they tend to lack a distinct ability that can help them bust through the Stonewall's guard and are forced to play passively around it instead.",

    "However, as useful as defensive Stonewall types can be, their inflexibility can lead to some major issues against the same sturdy types that give them a tough time on offense. While they share a lack of flexibility with Discipline types, Stonewalls are not nearly as measured and in control as their ground-bound brothers, resulting in Discipline players being able to work themselves into the paint like they're parallel parking an SUV. Since Stonewalls lack the low center of gravity to halt that kind of back-down, they'll eventually be pushed under the rim by the Discipline type if help doesn't come.",

    "Physical types will look to get up into Stonewalls at all times, as Stonewall types are often tall, lanky, not super agile on their feet, and can be quite slow to react at times - at least in comparison to the Physical player. When tasked with staying with a driving Physical type, Stonewalls may simply be unable to stop their momentum, and will end up picking up a whole lot of fouls in the process.",

    "Switchers remain a problem as well, given  that they can operate an inside-outside game that takes the Stonewall out of the paint and into an area they're far from comfortable defending. Switchers can also use that as a chance to attack off the dribble and get to the hoop with the Stonewall having to contest a bullet-like drive from behind the play.",

    "That kind of inside-outside game is even more pronounced with Flow types, who can move in and out of different playstyles and find numerous ways to put the Stonewall at a disadvantage. Their ability to be effective from so many areas of the floor, and in so many different ways, completely throws the Stonewall player off balance and negates a great deal of their defensive value.",

    "Entanglers are perhaps the most obvious problem for Stonewalls, as Stonewall's rigidity and mobility issues are a dream come true for an Entangle type's foul-seeking style of play. With all the ways Entangling players can get into the body of Stonewalls, or bait a Stonewall to move into theirs, it becomes a matchup that is such a disadvantage for Stonewall types that they'll often hide off on another player and play the role of a help defender, instead of the defensive anchor they're most comfortable being.",

    "<span style='font-weight:bold'><span style='color: #b8a038; font-weight:bold'>Historical Offensive Stonewall Types:</span> Walt Bellamy, Otis Thorpe, Kevin Willis, Nenad Kristic.</span>",

    "<span style='font-weight:bold'><span style='color: #b8a038; font-weight:bold'>Historical Defensive Stonewall Types:</span> Dikembe Mutumbo, Marcus Camby, Theo Ratliff, George Johnson.</span>",
  ],
  [
    "<span style='color: #B8C26A; font-weight:bold'>Pest</span> types are high-activity, pesky, aggressive players that are looking to get involved in as many plays and possessions as possible. They are typically players who are smaller and/or lighter than normal for their position, but use that advantage to take angles and use space in a way that their opponent isn't used to accounting for. Whether that's poking a ball away from behind, or splitting a double-team to drive to the rim, the Pest is can be a unique and irritating challenge.",

    "Offensively, Pests succeed as specialized counters to two potent defensive types: Entanglers and Cerebral types. Against the foul seeking Entangle types, their high activity level, agility, and maneuverability lets them avoid the Entangler's wall of thorns - no charges allowed!",

    "Cerebral defenders excel based on how well they can read a defense and knowing an opponent's tendencies, but the hectic, erratic, and difficult to anticipate movements of the Pest make for a very difficult cover, especially considering Cerebral types don't tend to be the fastest players on the floor.",

    "While they do quite well against those two types, offensively inclined Pests have their work cut out for them as they can get caught in a ton of unfavorable matchups. For instance, Physical players are just athletic enough that in conjunction with their size, they can deny the Pest's movement quite well. Switchers do well against Pests for a similar reason, as they're able to use their lateral agility to keep up with the in-and-out probing style of the Pest with ease.",

    "Another type whose movement makes them a tough defender for Pests are Separators, who are unparalleled defenders in space, making it quite difficult for a Pest to find an entry into the lane, and can fly in to smack floaters out of mid-air. Isolationists can deny Pests the ability to get downhill as well, usually focusing on suffocating them before they can get past half-court and trapping them into a one-on-one situation right away.",

    "Tricky types are also a real thorn in their side, as they are often as good or better at things the Pest specializes in, and can turn that erratic style of play against the Pest by subtly coercing them into unfavorable spots on the floor. Teamwork types use a similar, albeit less deceitful strategy to stifle the Pest, and will direct their team's defense to swarm the Pest and force them to give up the ball.",

    "The last type who are a challenge for Pests are defensive Heat Check types, who employ equal high-energy play, but with significantly more firepower, which lets them engulf Pest players on the perimeter and scorch any shot they put up near the rim.",

    "Luckily for Pests, their strengths and weaknesses are not quite as unbalanced on defense. While super Physical types may stifle them on offense, Pests turn that around when they're the defender. Their ball-hawking playstyle lets them attack Physical player's subpar handles, forcing them to pick up the ball and generating turnovers at a high rate, and their ability to quickly buzz into a play lets them get in front of reckless drivers and sacrifice their bodies to pick up offensive fouls.",

    "They employ a similar technique against Entangle types who look to find spaces in the defense to root themselves into unwitting defenders. That slower style of scoring allows Pest types to fly in and attack the ball to get a steal or move into the path of Entangler when they least expect it to pick up a charge. Their agility allows them to quickly avoid the Entangle type if they try to get a foul against them as well, making Pests an extremely frustrating foe.",

    "Discipline types face a similar issue against Pests, as they too employ a slow and measured approach to scoring, giving the Pest plenty of time to poke the ball out of their hands or force the Discipline player to reset the play to avoid being doubled and turning the ball over.",

    "Defensive Pests do continue to fare poorly against explosive Heat Check types, which is a real problem considering how drawn they can be to a hot hand. Pests will naturally want to try and double high volume scorers, but when a Heat Check type is in the zone, they can simply ignore Pest's attempt to irritate them, beat double teams, and score over them at will.",

    "Separators remain an issue for Pests as well, as their off-ball movement combined with their longer strides allow them to create a gap between them and the Pest that the Pest can't close. If the Pest catches up to them, a shooter can fire over their contest, and a leaper will rise to heights they cannot reach. Separators tend to score off very few dribbles too, giving the Pest very few opportunities to strip the ball away.",

    "It should come as no surprise, but Pest types are easily squashed by offensive Stonewalls. The towering rim-rattlers can all but ignore Pests as long as they make sure to keep the ball high, and any attempt they'll take will be through the hoop before the Pest can even touch the net.",

    "<span style='font-weight:bold'><span style='color: #B8C26A; font-weight:bold'>Historical Offensive Pest Types:</span> Allen Iverson, Tony Parker, Ty Lawson, T.J. Ford.</span>",

    "<span style='font-weight:bold'><span style='color: #B8C26A; font-weight:bold'>Historical Defensive Pest Types:</span> Norm Van Lier, Don Buse, Slick Watts, Brevin Knight.</span>",
  ],
  [
    "<span style='color: #A284A2; font-weight:bold'>Tricky</span> types are quite rare and many players are probably glad that's the case. They are known for finding ways to create advantages in ways that other types can not often see or anticipate, even the most calculated players in the league rarely expect the unique and, at times, dubious nature of their game. They will look to find ways to manipulate the game or other players in a way that benefits them, to the point that they're willing to go outside the bounds of the game itself or bend the rules to gain that advantage. Sometimes that's trying to get into other players (or referees) heads by constant haunting banter, other times it is willing to feign death to sell a phantom foul.",

    "Generalists prove to be completely ineffective against Tricky types as well, as they lack a specialized way to counter or avoid the tricks and traps set before them. Even if they try to stick to a standardized game plan and play as mistake free as they can, the Tricky type will egg them on and push them across their own line in the sand, causing the Generalist to play a style of basketball they are wholly uncomfortable with.",

    "Offensive Tricky types are effective against pretty much everyone, though they are especially good against Cerebral types. Cerebral players rely on unparallelled study of the game and their opponent, allowing them to outthink other players, and being able to anticipate what someone will do before they do it. However, Tricky types play the game quite a bit differently than everyone else, causing a real conundrum for a Cerebral type who operates on expecting the reasonably expectable. Even if they try to catch on to the Tricky player, they will spend so much time trying to figure out what is and what is not a trick or a trap that they'll suffer a paralysis of play as they become preoccupied with trying to avoid the trickery. This is, of course, greatly exacerbated by the Tricky player's jawing.",

    "This is the same type of game that Tricky types fall into on defense, funnily enough. The offensive Tricky player is always at an advantage when matching up with their defensive counterpart, as the defender must react to the offensive player, and in this case that means that the Tricky defender spends far more time trying to think about what they have to avoid than they do actually defending.",

    "On the flip side, Tricky types cannot fool a Generalist type when being defended by them. If the Generalist is committed to not playing outside of their skill set, or freestyling outside of the defensive game plan, then they simply won't fall for the Tricky type's attempts to bait them into mistakes. They aren't going to think about it, they're just going to do what they're told to do, and not do what they're told not to do. This makes them a terrible target to attack, so Tricky players will opt to simply avoid them instead.",

    "Tricky types are highly effective against cerebral players however, as the more necessary thinking and processing is for a player, the more they can be distracted by the sneaky player's antics, or worse, preoccupied with trying to avoid their trickery and trying to anticipate things that may or may not be coming. Funnily enough, sneaky types are also extremely effective against each other, as they can get trapped in trying to out-do and irritate each other, leading them to abandon the game plan as a whole and fixate on each other instead.",

    "Defensively, Tricky types can put Pests and Isolationists at a serious disadvantage. Isolationists rely on being in close contact with their defender, trying to out-maneuver them with footwork, handles, and a superior feel for the game; however, Tricky types have a deep bag of counters to trap the Isolationist with, which can force the Isolationist into super difficult shots and leverage their scoring aggression against them, as the Tricky type sets up for offensive fouls. Furthermore, Tricky types really want to get in the head of their opponents in order to take them out of their game, and all that time spent within the Isolationist's earshot will make for plenty of chances to do so.",

    "Offensive Pests are just completely out of luck against Tricky types, as everything they can do, the defensive Tricky type seems to be able to do better - at least when matching up with each other. All of the Pest's high-activity, jittery, probing in-and-out type of play will be easily shadowed by the Tricky player, resulting in the Pest running around for an entire possession trying to get out from the gaze of the trickster and right into defenders and traps.",

    "Tricky types become a nightmare for Physical types as well, who already have the highest propensity to foul in the game. Their over aggression and physicality will be immediately taken advantage of by the Tricky defender, who will either bait them into offensive fouls, flop the foul, or be in the ref's ear about the 'reckless play' of the Physical player until a whistle comes. The often hot-headed Physical type will also often be baited into technicals by particularly chatty Tricky types, who know just when the ref can and cannot hear them,  and worse yet be goaded into some very dangerous fouls that may get them ejected. All-in-all, they become useless at best, and more likely a negative when defended by the Tricky type.",

    "<span style='font-weight:bold'><span style='color: #A284A2; font-weight:bold'>Historical Offensive Tricky Types:</span> Isiah Thomas, Pete Maravich, Rajon Rondo, Muggsy Bogues.</span>",

    "<span style='font-weight:bold'><span style='color: #A284A2; font-weight:bold'>Historical Defensive Tricky Types:</span> Gary Payton, Bruce Bowen, Bill Laimbeer, Danny Ainge.</span>",
  ],
  [
    "While <span style='color: #9FA19F; font-weight:bold'>Switch</span> types are not as flexible as Flow types, nor do they have a great array of skills like a Generalist, they are not nearly as rigid as a Stonewall, or as specifically specialized as a Pest. They are quick enough to play on the perimeter, strong enough to  play on the post, and agile enough to be able to modulate between the two on any given possession. That unique form of versatility makes Switchers a highly valuable type for any team.",

    "Compared to their defensive counterparts, the number of offensively inclined Switchers is quite low. The most common archetype is a player whose scoring mostly comes through their ability finish plays around the rim with rocket-like verticality and pulverizing power, but also has the ability to bring the ball up, act as a tertiary playmaker, and do a little damage with the jumper - at least enough to require closing out on them. This combination of size, speed, and strength allows them to break through the most solid of Stonewalls, who are either too slow to get to the ball in the air, or too rigid to not be blasted apart by the Switcher laying down the hammer. Clutch types struggle in very much the same way, as they'll either be broken apart at the rim, or be split apart by a straight line cut down the lane.",

    "While Teamwork types normally excel against hyper-physical players, the Switcher's ability to swap between being a rim-seeking missile and a wholly capable playmaker can really stifle the Teamwork type's ability to direct their team's defense in a timely manner, resulting in the Switcher being able to buzzsaw through defenses with ease.",

    "Switch types find a tough matchup in their defensive counterparts however, given that this type tends to be a bit more nuanced and useful as a defender rather than a scorer. As such, their playmaking and rim attacking are fairly easily countered, and they end up being the dull blade.",

    "Defensive Flow types pose the opposite problem for Switch types. Since Switchers are more of a dual-mode type of player, a defensive Flow type's ability to move between spots on the court with unparalleled ease negates the Switcher's biggest advantage - their versatility.",

    "Switch types don't just struggle with hyper flexible types though, as a defensive Heat Check player with a specialized paint-based hot zone is far more dangerous than the driving or play finishing Switcher, and the exact type of opposing force that can liquify even the best Switcher shot attempts. This allows the Heat Check type to get rolling off of positive defensive plays and become a five alarm fire in the blink of an eye.",

    "Highlight types function similarly on defense, given that they excel in overwhelmingly high energy, high pace, high effort moments as defenders. While the Switcher can attack the rim with incredible force, a Highlight type will spot the opportunity to strike, bolt towards the Switcher, and funnel all of their energy into an acrobatic, full powered contest that the Switch type will end up getting fried by. Similarly, a Highlight type is simply too quick and precise for a Switch type on the perimeter and will quickly short circuit one who is trying to be a processor and playmaker.",

    "Despite some unique advantages on offense, defense is where the Switch type really shines. While they may not be as quick as a guard, they're agile enough to stay in front of most speedy players and large enough to contain the more slithery and probing ones. When put on a big, despite being physically smaller, switchers are uniquely strong for their size and extremely sturdy, allowing them to hold up admirably in the post and paint against a back down or lob attempt. This allows them to choose to freely swap between almost all opposing player roles and a staggering eleven out of seventeen total types.",

    "Their quickness becomes an advantage against traditional bigs like Stonewalls, allowing Switchers to get around larger opponents and disrupt the entry pass by fronting the big or attacking the incoming pass itself. Switch types who start on a perimeter player but are roped into a pick-and-roll situation also have no problem flipping roles, taking on the opposing big and stifling the action. That versatility is very problematic for Separator, Clutch, Teamwork, Pest, and Cerebral types whose use of screens is fundamental to their offensive strategy.",

    "With the Switch type being able to cleanly swap off of their main cover and onto a new one, they are extremely stifling to Separators who come off a screen looking to shoot or cut, but see that the Switcher has flipped to take them and is up in their grill right away. This is similarly frustrating for Clutch types who will look to use screens to gain the inch of space needed to drill a timely shot, but find that Switch types can negate screens with ease and stay right with them in these moments.",

    "For Pest types, Switchers are simply the perfect mix of athletics to stop them in their tracks. Too quick to beat off the dribble, too large to get around easily, and for more passing oriented Pest types, the Switcher's screen navigation all but removes the Pest's last option for offensive success.",

    "Teamwork and Cerebral types who are almost always more playmaking based, and for whom the use of screens are a fundamental and necessary part of their offense, run into the same issue against the Switch type. The Switcher will either blow up their roll target, stick and contest someone who pops instead of rolls, or be in the position to switch onto the Cerebral or Teamwork type and lock them up with clean, precise, effective on-ball defense.",

    "That on-ball defense proves to be a serious issue against Generalist types as well, who are not likely to have a high enough level of skill to punish the Switch type, Entangle types who look to seek contact and push through defenders but will find that Switchers are flexible and durable enough to bend but not break under the pressure of the foul baiting, and Nullify types who beat their matchup by honing in on a critical weakness, but won't find a clear flaw in the Switcher's approach to defense that they can take advantage of.",

    "Switch types are most effective against Isolation types, as the Switcher's excellent on-ball defense on the perimeter and post, mismatch negating versatility, and excellent size and athleticism become the perfect defensive alloy to construct a cage around an Isolationist and keep them from doing any damage at all.",

    "While it seems that Switchers are the perfect defender, there are still three types of players who find notable success against Switch types: Physical types who are even more athletically gifted to Switchers and can break through their shield-like defense by force, Heat Check types who can bend the Switch type into following them to their their hot zones, and Discipline types who are patient enough to grind the Switcher down by hitting the same location over and over again.",

    "<span style='font-weight:bold'><span style='color: #9FA19F; font-weight:bold'>Historical Offensive Switcher Types:</span> Elgin Baylor, Blake Griffin, Larry Johnson, Richard Jefferson.</span>",

    "<span style='font-weight:bold'><span style='color: #9FA19F; font-weight:bold'>Historical Defensive Switcher Types:</span> Gus Johnson, Shawn Marion, James Posey, Luc Mbah a Moute.</span>",
  ],
  [
    "<span style='color: #FF8000; font-weight:bold'>Heat Check</span> types are players that produce in quick, explosive bursts, rather than a slower, more sustained, consistent manner. When they come into a game they're looking to exert a maximum amount of effort in the set amount of opportunities they're given, then cool off and allow their teammates to get going before they're ready to erupt again. If they're a starter that might mean their team starts the game by going to them right away, and if they come off the bench - which many do - then they're going to be checking in the game with guns blazing. If they string together enough positive plays and start to heat up, they're liable to catch fire, and when that happens a Heat Check type can become nearly unstoppables.",

    'All Heat Check types are especially effective in certain areas of the floor called "hot zones", or an area of the floor where they are most comfortable and operate the best in that is unique to them. For some it\'s the three point line, for others it may be the high post, and a select few may even be powder kegs in the paint. As such, their effectiveness depends on their ability to drag players into these zones so they can cook them.',

    "The vast majority of Heat Check types are scorers in nature and many are used as instant offense off the bench due to their ability to be a burst of immediate energy. That zealous style of play makes Heat Check types exceptionally effective against Pests, whose predilection for running towards the action and trying to create turnovers as a help defender will draw them into the Heat Checker's hot zone, a dangerous place to be. This often leads to giving too many and-1 opportunities to the Heat Check player who will fire shots up when the Pest gets too close, allowing the opposing team to get into the bonus early.",

    "Since a parade of free throws is one way for Heat Check players to keep the fire burning, Entangle types run into a similar issue. Entanglers want to embed themselves into a defender's space in an attempt to junk up plays, force discomfort, and create turnovers. Heat Check types will prey on this by getting Entanglers into their hot zones, where the Entangler's defensive style of rooting themselves in place will get them grilled by the Heat Checker who is more than happy to fire up shot after shot from their spot.",

    "Clutch types are great against many potent scorers, but against Heat Check types, they too fall victim to the hot zones. If a Heat Check type is getting the ball in a late game or late clock scenario, it is assuredly going to be in the spot that they want, and their effectiveness in that area is enough to simply melt any Clutch type who dares attempt to make a heroic play as a defender.",

    "Heat Checkers are also one of the few effective offensive types against Switchers, making them a huge value for most teams. The exceptional defensive Switchers have all the physical tools to keep up with nearly any on-ball scorers, but their lack of a specialized zone of operation means that Switchers will end up following a Heat Checker into their hot zone, rather than pushing them out of, and be rendered much less effective as a result.",

    'While it seems like Heat Checkers can be completely uncontainable scorers, they actually do have some weak points on offense. For one, they will struggle against Stonewalls as they have their own type of "hot zone" around the rim. Even against a paint dwelling Heat Check player, the Stonewall has a superior mastery of that area, and can suffocate Heat Check\'s scoring attempts. Additionally, they are excellent help defenders and their strength against Heat Check types lets them wander into hot zones unperturbed to contest shots - a huge help to a struggling teammate as they create a wall that Stan Van Gundy could only dream of.',

    "Flow players are also able to stifle Heat Check types thanks to some excellent help defense instincts. While Switchers are great at swapping from player to player, Flow types excel at switching or swapping to different areas of the court. Flow players will be able to sail in and out of coverages without breaking their team's defensive scheme, allowing them to disrupt Heat Check players in their zone, or even wall them off from accessing it in the first place, no matter where on the floor it may be.",

    "Nullify types are a natural counter to Heat Check types, as their approach to defense is finding what their opponent specializes in and taking that away from them. Since Heat Check types have a very obvious approach to offense and all but require getting into their hot zones to reach peak potency, a Nullifier's job is extremely straightforward: cut the Heat Checker off from their hot zone and force them to play anywhere else on the court but there.",

    "Their last challenge on offense is against their type counterpart. Defensive Heat Check types will be used against offensive ones when they have mirrored hot zones, effectively cancelling each other out, and making it so the offensive Heat Checker is having to score without the comfort of their best spots on the floor. This results in a greatly diminished ability to get on a hot streak for the scorer, which is a good tradeoff at the cost of the diminished defensive potential.",

    "On top of being effective defenders against their own type, they remain very good against Pests, Switchers, Entanglers, and Clutch players for similar reasons that they excel against them on offense.",

    "Since many Heat Check types may come off the bench and in exchange for less minutes on the court, this allows defensive types to expend a great deal of energy while they're out there - and as we know, defense is 50% effort...or at least something like that. That ability to go full blast is especially effective against Pest and Clutch types, who often rely on being able to out-hustle or out-effort opponents with a timely burst of energy when they need to get something done on offense.",

    "Defensive Heat Checkers are also effective against Teamwork players, making them one of only three types that can consistently stifle them. Heat Check types are excellent at blowing up set plays that enter their zone, as well as high-risk, high-reward plays as the help defender if something develops in their zone when they are outside of it. For orchestrating teamwork types, this can significantly limit their options on offense by forcing them to get out of the structure and sets they're used to.",

    "While Heat Check types are strong defenders against a great number of players, Stonewall types will continue to be a major counter to them. The Stonewall's superior dominance around the rim collapses the hot zone of the Heat Check player, snuffing them out, and more often than not resulting in a whole lot of free throws for the Stonewall type.",

    "Discipline types' measured and patient game allows them to use two super-effective strategies against Heat Check players  The first is to will methodically work their way in and out of a hot zone and wait for the Heat Checker, who can get rather impatient with their boom-or-bust playstyle, to overplay their hand and make a mistake. The second strategy is to develop things on offense so slowly that the Heat Check player's minutes will be soaked up in as few possessions as possible, resulting in the Heat Checker cooling down without having done much of anything.",

    "Flow types are the third and last defensive counter against Heat Check players as just like on offense, the Flow type's ability to drift from spot to spot on the floor while retaining full effectiveness lets them pass through hot zones without a care, and slide into areas where the Heat Check player is at a disadvantage but has no other option but to follow.",

    "<span style='font-weight:bold'><span style='color: #FF8000; font-weight:bold'>Historical Offensive Heat Check Types:</span> David Thompson, Walter Davis, Lou Williams, Freeman Williams.</span>",

    "<span style='font-weight:bold'><span style='color: #FF8000; font-weight:bold'>Historical Defensive Heat Check Types:</span> Michael Cooper, Larry Nance, Manute Bol, Chris Anderson.</span>",
  ],
  [
    "<span style='color: #2980EF; font-weight:bold'>Flow</span> types are defined by their fluid playstyle that allows them to be effective as a primary option in nearly any role you can think of. They are players who can be playmakers or play finishers, on-ball or off-ball, high volume or low volume, you name it, they can do it, and do it from anywhere on the court.",

    "What differentiates them from Generalists, who have a wide variety of skills themselves, is that Flow type's effectiveness comes not just from their remarkable flexibility, but their ability to leverage that flexibility into making themselves a constant threat. Flow types achieve that by making opponents react to what they are doing in the immediate, in addition to all of the things they have the capability of doing at any given moment. In having to calculate all the possibilities a Flow player’s skill set entails, opposing players can quickly become overwhelmed, creating a great deal of opportunities for the Flow player to capitalize on.",

    "Put another way, while both types have a vast amount of skills, Generalists' value comes from using those skills in a way that makes it very difficult for the opponent to take advantage of them, whereas Flow types use their skills in a way that lets them take advantage of the opponent.",

    "That style of play only works if the Flow type is steadily positive in their play, so while they still can be a tidal wave at times, in order to remain consistent they prefer to focus on being more of a steady stream. As such, if a Flow type is not scoring well, they can function as a playmaker. If they are matched up against a tough assignment on defense, they can impact the game as a help defender or a free roaming safety. It is an approach that revolves around letting the game come to them, not forcing anything, and remaining adaptive.",

    "On offense, Flow types tend to have a primary role that the rest of their game trickles out from. Some may function as main scoring options due to their ability to drain shots from just about anywhere on the court, others may be secondary or tertiary off-ball scorers options that find holes in the defense, flood into them, and rack up points that way. They could even be primary playmakers who can pass the ball from anywhere on the court, secondary playmakers that function as the connective waterway of the offense, or even remarkable glue guys who can be a little bit of each of these things at all times",

    "This floaty approach to offense allows Flow types to greatly disrupt Stonewalls. Since defensive Stonewall types are often larger, slower, stiffer players, Flow types are not just able to pull them all around the court into areas they are uncomfortable in, but also greatly diminish a Stonewall's effectiveness in their homebase around the rim. Though Stonewall players have few opponents they cannot contain down low, Flow types have two ways to to counter them.",

    "The first is through their diverse scoring style which, outside of their ability to get buckets from all over the floor, sees them be able to do so with a number of different moves. While their bag may not be as deep as an Isolationist, they still have quite the repertoire, and tend to have equal effectiveness between each of their moves. This creates a serious challenge for a Stonewall, who is used to only having to defend against one or two go-to actions from an opponent.",

    "The second counter is through the Flow type's ability to act as a playmaker, which is something that Stonewalls are simply not used to defending. A Stonewall can operate as a post defender, a help defender, and a defensive anchor in the middle, but making them guard someone who makes them account for their scoring as well as their playmaking is too much to ask.",

    "Discipline types suffer many of the same issues, as their grounded, measured, mistake-free style of defense is based on sturdy, immovable prevention. While they can follow opponents around better than Stonewalls can, the Flow type is going to try to move them around a lot, putting Discipline players on their heels as they try to plug up holes and stop the defense from leaking. As time goes on this proves to be a futile strategy and the Discipline type's defense will soften up, allowing for the Flow type to wash them away.",

    "Defensive Heat Checkers have a rough time as well, as their boom-or-bust playstyle is a difficult approach to take against the steady, consistent, constant pressure a Flow type puts out. It is doubly ineffective because Heat Check types are always trying to force their matchup into their hot zones, something that you simply cannot do to a Flow type as they will not only maneuver through those zones and float Heat Checkers right out of them, but can also stick around inside that zone and be just fine, given their versatility allows them to boil, steam, or poach the Heat Check defender.",

    "Offensive Flow types have only a few matchups they have a tough time with. Most notably are Entanglers, who are trying to root themselves into their opponent, be physical, be invasive, and ultimately want to cause them to make mistakes that result in live ball turnovers or offensive fouls. While Flow types are very flexible and maneuverable, they're not exactly avoidant. This means Entangle types can not just  remain locked in with the Flow type and float along with them wherever they go, but absorb the Flow type's attempts to be physical, get in their way, and clog up their freedom of movement at every turn.",

    "Nullify types are also a strong counter to Flow types, as their physicality, mobility, and expertise in removing an opposing type's strength can be used to disrupt the Flow type's offensive approach. While the Flow type is too nuanced for the Nullifier to find success in just taking away a single skill of theirs, a savvy Nullify type can focus on diverting the river from where it wants to go by boxing the Flow type in, move them away the rest of the floor, and then draining the lake once it has been dammed off.",

    "Flow type's last challenge is their defensive counterpart. When matched up against a defensive Flow type, what results is sort of a \"two waves crashing into each other\" situation. The defensive Flow type is just as maneuverable, just as versatile, and just as strong, so if they're pushing against the offensive Flow type then both players will get stuck in place, swirling around each other, and getting nowhere. That is a great outcome for the defender.",

    "In addition to being highly effective against offensive Flow types, defensive Flow types remain effective against Heat Check types for the previously mentioned reasons, and become good defensive covers against Clutch types and Switchers.",

    "Against Clutch types, a defensive Flow type is able to use their maneuverability to navigate to where they believe the Clutch player is going to execute a play, avoiding getting stuck on obvious screens, and limiting the Clutch type's effective unpredictability. That ability to naturally switch, slip, or slide to different parts of the court, effectiveness in just about every zone. and a wide variety of defensive skills, lets the Flow type deal with the majority of problems the Clutch type presents.",

    "Against Switchers, defensive Flow types are able to use their fluidity to their advantage once again. Since Switch types are rather binary, A-to-B type of navigators, Flow types will have no problem keeping up with them and even containing or diverting their pathways. Additionally, while the Switcher's adaptability is an advantage in many matchups, it pales in comparison to that of a Flow type, thus negating the Switcher's biggest strength as an offensive player. Ultimately, the amount of pressure a Flow type’s versatility puts on a Switcher will make them crumple like a poorly made submarine",

    'As far as weaknesses go, defensive Flow types only have two: Entanglers, and Highlight types. Entangle types should come as no surprise, as they are effective against Flow types in such a foundational way as defenders, that an offensive Entangler was bound to be as well. Despite all the different ways a Flow type can approach a defensive situation, and all the different areas of the floor they can be impactful, an Entangle type has just as many ways to create contact. Flow types don’t have quite enough speed or quick-twitch reaction time to avoid the foul-seeking Entangler, and as such, their "let the game come to me" style of play results in a little more game than they can handle. This is the case for Flow defenders in man coverage with Entangler, and fluid help defenders who want to play all over the court, as they run the risk of moving right into the path of a free throw hunting Entangler.',

    "The Flow type’s defensive versatility and maneuverability is great when dealing with slower players, smaller players, or less agile players, but against offensive Highlight types who are so fast and explosive in how they play, they look more like quicksand. Since Flow types are unable to contain Highlight types in man coverage, aren’t quick enough to help or switch to them, and can be outmaneuvered in all but the most perfect moments of anticipatory positioning, they will end up conceding a ton of  easy baskets to the Highlight type - so many that their team will have to change up defensive coverages when faced with one.",

    "<span style='font-weight:bold'><span style='color: #2980EF; font-weight:bold'>Historical Offensive Flow Types:</span> Larry Bird, Pau Gasol, Detlef Schrempf, Boris Diaw.</span>",

    "<span style='font-weight:bold'><span style='color: #2980EF; font-weight:bold'>Historical Defensive Flow Types:</span> Scottie Pippen, Paul Milsap, Nate McMillan, Bill Hanzlik.</span>",
  ],
  [
    "<span style='color: #82C274; font-weight:bold'>Entangle</span> types are extremely polarizing players due to their obstructive and irregular playstyle. Entanglers are one of the few types who are looking to bring more physicality to the game, but unlike Physical or Stonewall types who do so by strength, size, athleticism, and hustle, Entangle types do so by being as in-the-way and unavoidable as possible. In doing so they create a situation wherein the defender is unable to avoid contact all together, either by baiting the opposition into mistakenly creating it themselves, or by positioning themselves into the defender who is far too close to avoid it.",

    "In basketball, where freedom of movement and the ability to quickly react to a play is so imperative, Entanglers have found a way to control space and speed in such a way that they force the opponent into playing what can feel like a completely different game altogether. While most of the time a game of basketball feels like a run through an open field, Entanglers can turn it into a dense, trap-ridden jungle where a player has to either try and fight through it with a machete, or do their best to avoid falling into one of the thousand pits of snakes. As you can imagine, this can be an extremely effective strategy, but is an equally frustrating experience for opposing players and fans alike.",

    "On offense, Entanglers will slowly work their way into the opposing defense, plant themselves into an area of the court that they like, and figure out how their defender is reacting to them. What the Entangler is primarily looking for during a possession is often not a good shot, but to pull an unwitting defender off the trail and into their space, deep into the proverbial hardwood jungle. Then when the Entangler sees that their opponent is caught in the rough and all turned around, that’s when they will strike. As they say, you may not know where you are in the jungle, but the jungle always knows where you are in it. Ultimately, this results in a whole lot of free throws for the Entangle type as players cannot avoid fouling them, or defenders play so passively to avoid getting all tangled up and committing fouls that the Entangler ends up with a ton of open shots.",

    "Unsurprisingly, offensive Entanglers are extremely effective against Stonewalls because of this playstyle. Stonewall types' rigidity and lack of speed, agility, and at times coordination, puts them at a major disadvantage in general when trying to avoid fouls. Against a player who is specifically looking to create contact and get fouled, there is very little recourse for the Stonewall type, making Entanglers some of the best big man scoring options and rim attackers and a great way to send an opposing big man to the bench early.",

    "Discipline types also find Entanglers tough to deal with, as their slow and methodical pace ends up playing into the Engangler’s strategy. While Discipline types will approach things patiently and try to play as mistake-free as possible, offensive Entanglers will only up their chances of getting fouled the longer a possession draws out. The Discipline type will have a tough time finding out how to both play to their philosophy, while also trying to avoid the Entangler, and will often end up feeling like they did everything right just for them to end up fouling the Entangler who sprouts through their defense like a weed in the sidewalk.",

    "Flow types end up having the opposite issue with Entanglers, wherein they will try to avoid and maneuver the Entangler at all costs instead of trying to outlast or contain them. While Flow types may succeed in not sending Entanglers to the line, their evasive approach to defending Entangle types ends up giving up a ton of easy looks, which besides the obvious problem that poses, also greatly reduces the normally high amount of energy and physicality the Entangler needs to exert on offense. That allows Entanglers to stay in the game and remain highly effective for much longer than normal.",

    "Though Entangle types are hugely valuable scorers due to their effectiveness against those three defensive types, they are not without significant impediments as scorers. First and foremost, they find it quite difficult to play against their defensive counterparts. When an Entangler is matched up against another Entangler, there are few times where the defensive player will feel out of their element. If the offensive Entangler is focused on playing the foul-baiting game, and the opposing defensive Entangler knows it, not only is the element of surprise gone but the defender will be trying to align themselves in such a way where any chop of the ax from the offensive Entangle type will send the tree topping back towards the direction that it came. As such, offensive Entanglers risk committing a ton of offensive fouls and will never generate enough trips to the line, nor easy buckets, to remain effective as scorers in this matchup.",

    "Isolation types are also a huge problem for Entanglers, as they are the superior one-on-one players and make the Entangle types’ attempt to draw a player into an uncomfortable or obscured area much less effective. The Isolation type will welcome an Entangle type who wants to try to get their roots down and create contact, as they will be able to study the Entangler and find out ways to not only avoid it, but cut them off from the areas they’re most effective in. More often than not, this ends up with Isolation type defenders wilting the Entangler’s scoring ability.",

    "Separators are quite effective against Entangle types as well, as they have an unparalleled ability to move through space while still remaining in the play, meaning they can freely avoid the Entangler’s attempts to hold them down while not getting too far away from them and giving up an easy bucket. A high flying Separator is also great in help, as they can launch towards the rim for blocks, which even if the Entangler puts a foul on the Separator’s teammate can still be useful as a way to turn and-1s into just a trip to the line.",

    "Pests are another defensive type who excel against Entanglers thanks to their speed, which along with their smaller size allows them to not just avoid all the obstacles that Entanglers can set up, but any attempt from the Entangler to initiate contact will be seen as overly aggressive and disproportionate use of force and end up being called an offensive foul. Entangle types’ slow, stationary, style also allows for Pests to buzz into the play as a help defender or doubler looking to attack the ball. Entanglers are often focused on their own space and do not see help coming, letting the diminutive Pest fly in and cause havoc, making the Entangler have to get the ball out of the jungle or risk having it taken away from them.",

    "Heat Check types are especially dangerous, as any attempt from the Entangler to hold a Heat Check type down will be met with an explosive movement to break them free of the viney-shackles and burn up the offensive possessions in an instant - especially if the Entangler type tries setting up in the Heat Checker’s hot zone.",

    "Switch types are also a good cover against Entangler’s, with their unique combination of size, speed, and agility, that grants them much of the avoidance of a smaller player but in the body of someone who is strong enough to stand up to an Entangler’s incursions. Entangle types will find it very tough to get past a Switcher who can seamlessly cut them off from where they want to go, and limits the Entangler’s ability to use screens on any team a Switcher resides on.",

    "Nullifiers are the final type of defender who can cause problems for Entanglers, as their specialty of being able to remove a vital part from another player’s game can cut the roots out from under an Entangler all together. Since Entanglers need to be able to seek and initiate contact to remain as effective as they’re relied upon to be, a Nullifier honing in on that and not allowing it to be done can turn a great jungle into a city park.",

    "On defense, Entanglers are looking to set up in a relatively similar way to their offensive counterparts. On the perimeter, Entangle types are looking to plant themselves into a defender in such a way that they’re able to restrain their movement without fouling, making it so the offensive player has to get away from ultra tight coverage without chopping away the Entangler’s hand, pushing them off of them, or running through them. They make it extremely tough to do so, which makes Entangler types exceptional at drawing offensive fouls, especially in the pick-and-roll as they’ll find their way into the cracks between the screener and the ball handler and get around screens in such an odd and surprising way that the screener will often react by moving to try and block them off.",

    "In the paint, Entanglers can be exceptional rim deterrents and drop bigs, as their ability to root themselves in the key and on the blocks allows the Entangler to create a defensive thicket that makes it so anyone who comes into the paint finds it exceptionally difficult to successfully navigate and find the light through the trees. Smaller Entanglers who may find themselves down towards the rim will also frequently find a way to plant themselves outside of the circle for a timely, totally unavoidable charge.",

    "Outside of the aforementioned advantage they have against offensive Entangle types, Entanglers remain super effective against Discipline types, given that the offensive Discipline type is always looking to make well-measured, repetitive, inch-by-inch attacks against the defense. While this may prevent them from being baited into fouls, they lack the athleticism and burst to cut through the Entangler’s defense, resulting in a wandering, time wasting type of offense that sees the Discipline player never finding the right time to make a play.",

    "Flow types also remain at a disadvantage, as their offensive ability being predicated on flexibility does not really allow them to counter a defensive Entangler’s game. While they can avoid the Entangler’s traps and not be slowed down too much by them, they can end up getting pushed out from where they want to go far too easily, as the Entangler will just keep walling them off from spot-to-spot on the court and turn what needed to be a straight line drive into the Amazon river.",

    "Highlight types face a movement related issue as well. While they certainly have the burst and athleticism that should be a major problem for Entanglers, their particular brand of movement and playmaking is very dependent on a type of speed that has to be ramped up into. Since the Entangle type can stick on the Highlight type and impede their movement, it creates a situation where a drag racer is forced to try and launch on the roads of San Francisco. Entanglers simply make it too bumpy and rough for the Highlight type to make their moves and build a head of steam.",

    "On the negative side, defensive Entangle types will continue to struggle with Separators who use movement and a ton of screens to create space for themselves. While Entanglers are very formidable at guarding pick-and-roll actions against ball handlers, their style of invasive screen navigation that excels at jamming up movement there does not work as well against off-ball movement and they’ll lag behind the play.",

    "Isolation types remain potent counters to defensive Entanglers as well. Not only do Isolationists excel in one-on-one situations, but their ability to pull the Entangler away from their team stops them from being able to gunk up the movement of an offense and get in the way of things. It also helps that when a referee can hone in on an action that is only involving two players and get a clear view of the play, Entanglers have much less room for error when trying to tangle someone up and get an offensive foul called.",

    "Pest types also continue to be effective against Entanglers, as their speed allows them to avoid a lot of the premeditated movements the Entangler makes to try and trip opposing players up, slow them down, and cause them to commit an offensive foul out of frustration or in an attempt to navigate their highly pressuring playstyle. Pests aren’t going to generate enough force with the ball in their hand to credibly dislodge an Entangler, so the Entangle type runs the risk of picking up the dreaded flopping fine in these matchups.",

    "Heat Check types are fantastic offensive types to employ against an Entangler as well, given that they can get into their hot zones quickly and completely render an Entangler’s defensive strategy ineffective. Heat Check types in their favored zones of operation will be too explosive for an Entangler to sink their roots into, or a deadeye shooter may just be able to ignore the Entangler all together and drain shot after shot over them. Oftentimes the Entangler’s brand of physicality will backfire on them against a Heat Check player, who has no problem letting one try to play up into them so they can use it as an opportunity to generate a bunch of and-1s of their own.",

    'Clutch types pose the exact same issue for Entanglers and are a particularly problematic cover. Since Entanglers are so invested in trying to create contact and "playing the rulebook", they can be a real detriment when a defensive possession calls for fundamental, straight up coverage. A Clutch type can take advantage of the foul-seeking Entangler by anticipating their attempted jam-up or trap and switching the play up on the fly, leaving the Entangler looking around for help from the refs or completely whiffing on a defensive coverage while being too slow to get back in the play. Equally problematic is the Clutch player’s ability to buy into the contact and pick up a free trip to the line, or sink a crucial shot while picking up a foul on the Entangler. Because of this, teams will often try and switch an Entangler off of a Clutch type at the end of games all together.',

    "<span style='font-weight:bold'><span style='color: #82C274; font-weight:bold'>Historical Offensive Entangle Types:</span> Karl Malone, Bob Petit, John Drew, Corey Maggette.",

    "<span style='font-weight:bold'><span style='color: #82C274; font-weight:bold'>Historical Defensive Entangle Types:</span> Alvin Robertson, Dan Roundfield, Raja Bell, Anderson Varejao.</span>",
  ],
  [
    "<span style='color: #FCD659; font-weight:bold'>Highlight</span> types are often the most exhilarating and electrifying players in a game, constantly looking to dazzle and impress with incredible displays of skill, vertical athleticism, and speed. While some types of players are looking to set up shop and grind out a possession, or think through a decision tree to decipher the best course of action, Highlight types' strategy is to strike before an opponent is able to react, as few players can match the Highlight types’ quickness, agility, and comfort level in making split second decisions. Though this forgoes a more thorough processing of the game by the Highlight type and makes them a bit boom-or-bust at times, it also forces their opponent to play at that same pace and try their hardest to keep up.",

    "With each flashy play the Highlight type makes, the more charged up they’ll become - not just from a growing inner-confidence, but the energy that their playstyle brings to their teammates and fans as well. Highlight types live for the pop of the crowd and feed off of being able to light up an entire arena with a thunderous dunk, ankle-snapping crossover, or blocking a shot into the 5th row. If a Highlight type can string together enough of these high-powered plays, they can go from being a static shock to an Earth-shattering lightning strike in no time, becoming an untouchable gamebreaker and giving their teammates a big jolt as well.",

    "On offense, the Highlight type is usually looking to attack defenses early on in the shot clock. As such, Highlight types tend to be perimeter-oriented players who have an incredible first step. Smaller Highlight types will have a particularly tight handle which allows them to launch past the point of attack in an instant, while still keeping complete control of the ball, whereas larger players possess an incredible leaping ability that lets them launch from outside of the paint and right to the rim. Even without the ball in their hands A Highlight type can always find a way to utilize their exceptional burst and acceleration to crack open a defense as an off-ball cutter and, most commonly, in transition where they are unparalleled playmakers and finishers in the open court.",

    "Offensive Highlighters are one of the few types that can outmaneuver Separators. While Separators are known for being extremely smooth and agile players who are able to expertly navigate screens or fly in as vertical help defenders, Highlight types are so quick that they are able to beat Separators to a spot with ease. If a Separator goes under a screen, the Highlight type will be behind them and heading towards the rim before they can turn around. If a Separator comes in to contest a highlight type at the cup, the Highlight type will be there first, and strike with a defeathering dunk - almost like a glider vs. an F-22.",

    "Flow types struggle with Highlight players for a similar reason. While Flow types excel at being able to float in and out of space, covering a wide range of players and locations, and having great positioning that allows them to be adaptable to many different situations, they still struggle with players that can either obstruct or demand more expedience in their movement. Flow types’ burst is something that has to have a ton of momentum behind it, which is unhelpful against Highlight types who, despite needing a little runway to get launched, are lightyears quicker in comparison. Ultimately, that difference in speed results in the Flow type's style of flexible, predictive-to-be-reactive defense being a conductor for the Highlight type, rather than grounding them. The Highlight type will hurry a Flow type, force them to react to a quick change of direction, unbalance them until they’re spilling over the edge of their shoes, and get a free run to the rim over and over.",

    "Where offensive Highlight types will have a tough time is when they run into opponents that can slow them down or meet them with equal speed and force, the first of which is their own defensive counterpart. Since Highlight types’ production is highly reliant on being able to hurry their opponent and whiz past them, a defensive Highlight player is a great option to use as a primary defender. All of their tools to excel on offense are negated by the defender having the same speed and same comfort-in-chaos, resulting in the offensive Highlight type being repelled as if they were a positive magnet trying to attach to another positive magnet, and expending a ton of energy to go nowhere.",

    "Nullify types can also defend Highlight types in a similar manner, as they specialize in completely taking away an aspect of a player's game. For types that have smaller toolkits to work with like the Highlight type, removing just one strength can be devastating to their effectiveness. If a Highlight type is ultra reliant on being able to burst past the point-of-attack, the Nullify type will key in on that and play a perfect prevent defense. If a Highlight type is someone with incredible handles they use to jump in and out of a play, the Nullifier will crowd them just enough to be able to attack the ball, but keep far enough away so they can match the change of direction. If a Highlight type is an off-ball menace who looks to back cut to the rim, the Nullify type is going to play far enough off them to cut off the action, while being close enough to contest any potential jumper, thus starving out their involvement in the offense. Not to mention, Nullify types tend to be mighty quick themselves!",

    "Entangle types take an opposite approach, punting on attempting to keep up with the Highlight type, and instead opting for a style of defense that is so obstructive that it greatly slows the Highlight type down. Not only will Entanglers be super physical with the Highlight type as to not give them the space to find a launch point, but they will also position themselves in such a way while doing so that they root themselves into a potential runway. That way, even when the Highlight type finds enough space to attack, the Entangler will have a part of themselves out there and in the way before the Highlight type gets there, and creating a bump in the road that the Highlight type will have to try and avoid and be sent off course, or committing an offensive foul while trying to cut through it.",

    "While those types are very strong against a Highlight type, no defender is as much trouble as the Discipline type. Discipline types are a complete counter for Highlight types, as their ultra-measured, mistake-free, rim deterrent style of play makes them impossible to hurry up - entirely negating what a Highlight type needs to do to gain an upper hand. The Discipline type will remain in lockstep with the Highlight type as their masterful positioning allows them to cut off a Highlight’s drive paths, while their zen-like patience means they’re not biting on any ball fakes, crossovers, or over-committing to try to force a turnover. Wherever the Highlight type wants to go, the Discipline type is meeting them there, like trying to get past someone in an open door who just keeps sliding with you.",

    "On defense, Highlight types excel as help defenders thanks to their quick reaction time, acceleration, and nose for big plays. Outside of their aforementioned strength against other Highlight types, they continue to be very strong against Separators. On off-ball screens a Highlight type can shoot the gap and attack an incoming pass for a movement shooter or cutter, causing a turnover or simply killing the passing lane before the play can progress. Defensive Highlight types shine as help defenders at the rim, as they are able to meet high-flying Separators at their apex and strike them down in a flash.",

    "Highlight types are great against Switchers as well, as their two modes of operation (tertiary driving playmaker and play finisher around the rim) are both well-covered by the Highlighter’s strengths. When an offensive Switcher is on the perimeter and in playmaking mode, their handle is not particularly strong, which allows the Highlight type to make a quick play on the ball. Despite being stronger than the typical Highlight type, if the Switcher looks to drive with the ball, Highlight types are quick enough to beat them to a spot and try to force a tough shot. The main battle between these two types comes around the rim though, as Switchers bread and butter is their missile-like off-ball cuts. A paint dwelling Highlight type is one of the few players that can shoot those plays down, often appearing out of nowhere to attack a Switcher mid-flight.",

    "Defensive Highlight types are useful against all but one other type, making them some of the most valuable and usable players in the sport. It should come as no surprise though that the one type they do not do well against is the Discipline type. Discipline types remain unable to be hurried or rushed, preferring to methodically get to where they want to go while protecting the ball. The Highlight types’ bursty, attacking style of defense has little effect on a player who patiently works their way through or into the opposing defense. The Discipline type will prey on an over-active Highlight player looking to block a shot or go in for a steal by leveraging their weight on postups to counter an aggressive swipe, shot faking the Highlight type up in the air and making them over-commit, or simply biding their time for the Highlight player to make a mistake while searching for a big play.",

    "<span style='color: #FCD659; font-weight:bold'>Historical Offensive Highlight Types:</span> <span style='font-weight:bold'>Dominique Wilkins, Connie Hawkins, Calvin Murphy, Jason Williams.</span>",

    "<span style='color: #FCD659; font-weight:bold'>Historical Defensive Highlight Types:</span> <span style='font-weight:bold'>Dwight Howard, Gerald Wallace, Josh Smith, JaVale McGee</span>.",
  ],
  [
    "<span style='color: #EF4179; font-weight:bold'>Cerebral</span> types are the chess masters of the game, basketball obsessives who have spent so much time studying the game that they know exactly how a play will develop, five, ten, twenty moves in advance. Their studiousness doesn’t stop there, as they have poured in just as many hours developing a mastery of their own individual style of play, so that in conjunction with their uncanny ability to predict what will happen in any given situation, they’re able to completely control a game with the ball in their hands. While each Cerebral type may take a different approach to how they play, just like the greats of Chess do, they all display a command of pace, body control, and eagle-like court mapping - which is to say where everyone and everything is at all times and what angles and space are open, or will be open, for them to take advantage of.",

    "On Offense, while some offensive Cerebral types may be more scoring oriented than others, they are all mismatch hunters. Cerebral types know everything about the players they play against and when something is an advantage or disadvantage for them, and as such, will always be trying to put themselves in the most advantageous position possible. This results in many of their possessions starting with pick-and-roll actions where they’re able to force matchups they want to attack, or create one to enable the attack for a teammate.",

    'Another binding agent that connects all Cerebral types is an incredible passing talent, as their foresight and grasp of the geometry of the court allows them to see, attempt, and convert on passes from angles and positions that few other players would ever conceive of, let alone attempt. This results in the majority of Cerebral types playing point guard for their team, regardless of their size, or what position they would "normally" be. Since they have developed such a deep understanding of the game, their opponent, and themselves, they are as reliable of decision makers as you will find. This results in many offensive Cerebral types being "helio" players, meaning a player who has the ball in their hands the vast majority of the time on offense while their teammates "revolve" or operate around them. Put another way, instead of running a specific offensive system with lots of set plays and structure that relies on the execution of everyone somewhat equally, the Cerebral types’ individual skill and how they use them to score and facilitate are the system and that responsibility rests on their shoulders.',

    "This allows them to be extremely effective against Isolationists, whose preference in taking a player away from their team can be completely leveraged against them by a Cerebral player. Isolation types have a well-deserved belief that if they’re in a one-on-one situation with a player, that is to their advantage, as they can suffocate that player with their on-ball defense, force them into bad shots, or make them give up the ball. The Cerebral type is well aware of this however, and has no problem encouraging that Isolation player to come out and guard them. A Cerebral type will often have a style of scoring that is counter-intuitive to the Isolationist’s style of defense, using the Isolation types’ aggression against them and employing a bizarre type of pace, movement, and pressure that befuddles most defenders and lets the Cerebral type saunter into the lane far too easily. More commonly though, the Cerebral type will use the Isolation types’ desire to force their opponent to give up the ball to their advantage, taking the Isolationist away from their team, or off of who they should be covering, and creating a mismatch somewhere else on the court that the Cerebral type will gladly fire a perfect pass over to.",

    "Offensive Cerebral types will also toy with Physical types in much the same way. While Physical types may have a reputation for being a bit brutish or less thinking than other players, this is very much not the case, as their athleticism is what they can employ in conjunction with a great understanding of the game in order to be successful. Unfortunately for them though, Cerebral types have a unique ability to turn their athleticism and aggressive, eager-to-accept-a-challenge style of play against them no matter how well they think the game. Offensive Cerebral players will have studied how each Physical type looks to attack on defense, how and when they leverage their strength and speed, and employ that same strange pace of play to create all these stopping and starting motions and possessions, forcing the Physical type to become unbalanced and aggress when they should back off, and back off when they need to aggress - like making a sprinter false start over and over. In doing so, the Physical type is kept off kilter, their high fouling tendencies shoot through the roof, and since the Cerebral type is well aware a Physical type is never going to deny a switch, they’ll happily pick on them all game.",

    "Offensive Cerebral types’ have such a strong control over the game that there are only two types of players that can pose a problem for them, and one of them is their defensive counterpart! Given that defensive Cerebral types have approached the game in a similar fashion and have gone to the tape on their offensive counterpart time and time again in order to understand their tendencies and intentions, it’s only natural that they pose the toughest challenge for their offensive brethren. While both Cerebral types approach the game like it was chess, the offensive player being the slightly favored white pieces, the defender being the second-to-move black pieces, the defensive type can always opt to play for a draw and stalemate the game - a great one could even win the match up entirely. Since the defensive Cerebral type can effectively force an offensive type into a standstill, and the offensive types’ team is so heavily reliant on their play to function on offense, this is a match up that must be avoided at all costs.",

    "The other type that offensive Cerebral types struggle with are Switchers. Cerebral types are very commonly looking to force mismatches as a way to exploit a team, as the more time they’re going against a beneficial match up, the higher their chances of positive conversion are, and with less effort and energy needed to do so. That is a huge benefit for a player who has the ball in their hands, orchestrating every facet of the offense, for much of the game. Switchers throw a huge wrench into that plan, as they are exceptional at negating various pick-and-roll situations, and fantastic on-ball defenders to boot. Offensive Cerebral types can’t call for a screen from a player being guarded by a Switcher, as the Switcher will just pop onto them immediately. If they try to screen a Switcher off of them, the Switcher can not only fight through a screen, or go under it while still sticking to their man, but can Switch to the roller or popper at just the right time if they think the Cerebral type is looking to pass and effectively shut down that possession. They become this impenetrable wall for a mismatch hunter and take away the best and easiest way for a Cerebral type to set up the offense for success.",

    "On defense Cerebral types excel against their offensive counterparts as previously mentioned, but also continue to pose a huge problem for Physical types. Offensive Physical types are aggressive players on both sides of the ball, and as scorers are looking to bully their way into good shots and attack the glass. Cerebral types will do their best to reroute that aggression and trap the Physical type into less than ideal situations that they lack the skills to reliably get out of. The Cerebral types’ expert ability to use their body to cut off driving angles and force players away from where they want to go is deadly for the Physical type, given that they’re not particularly agile, and often have a bit of a substandard handle for their position. Defensive Cerebral types will have no issue holding the cape out for the bull before pulling it back, forcing all sorts of travels, unforced turnovers, and general miscues from the Physical player. Even as rebounders, Physical types have a keen eye for where a ball may be coming off the rim and can expend less energy when battling for board, easily position themselves for the board or even a timely over-the-back foul on the Physical type.",

    "While Cerebral types are capable and effective defenders against just about every player, they do have two particularly frustrating opponents that they struggle with in Pests and Tricky types. Pests pose an interesting problem for the Cerebral type because of their quick, erratic, probing, and at times almost random style of play. Since Pests are buzzing all over the court at all times, changing their direction or decision at the blink of an eye, Cerebral types have a tough time judging what a Pest is going to do. It’s easy for a Cerebral type to understand players who adhere to a pattern of play, but since Pests are attacking offenses based on what space is immediately available rather than creating a direct opportunity for themselves, every possession is going to be extremely difficult to predict. Cerebral types can try to figure out where a Pest may go, but that type of guessing game puts them on their heels and leaves them playing a reactive, rather than predictive, style of defense, and that is the complete and total opposite of what they want to do.",

    "Offensive Tricky types are equally confounding for defensive Cerebral types, as their vexing style of rule manipulation, often-outside-of-the-game strategies, and emphasis on obscuring their intentions can greatly limit the effectiveness of the Cerebral players’ predictive powers. Tricky types are always looking to set up plays in a way that can fool or trap an opponent into making a mistake, and want to use the element of surprise, unpredictability, and deception to do so. While Cerebral types can do their best to memorize all the various cantrips of a Tricky player, the greatest trick of all is actually in getting the Cerebral type to do so. If the Cerebral type figures out all the things they need to avoid from a Tricky player and commits to not falling for them, they will have to spend quite a bit of mental energy to do so, and it forces the Cerebral player into defending their opponent in a hyper-specific, avoidant, cautious manner. Once the Tricky type notices that the Cerebral type is defending them like that, they’re then able to change up the playstyle all together and start playing a straight up, normal, trapless type of game. Since the Cerebral type is so worried about falling for the Tricky types’ nonsense and looking at the game through that lens, they will be poor defenders against a completely different style of play that they weren’t accounting for. If they shift their focus back to playing a normal style of defense, then the Tricky type will start up with their hijinx and lure them into their usual traps. Since the Cerebral types’ strength of play is predicated on being able to predict and understand what their opponent is going to do, this impossible guessing game the Tricky type creates puts them into a mental maze that they’re left blindly guessing how to get out of. It certainly doesn’t help that the Tricky type will be talking to them and trying to get in their head all game either.",

    "<span style='color: #EF4179; font-weight:bold'>Historical Offensive Cerebral Types:</span> <span style='font-weight:bold'>Magic Johnson, Jason Kidd, Rod Strickland, Andre Miller.</span>",

    "<span style='color: #EF4179; font-weight:bold'>Historical Defensive Cerebral Types:</span> <span style='font-weight:bold'>Mookie Blaylock, Andre Iguodala, Shane Battier, Eric Snow.</span>",
  ],
  [
    "<span style='color: #81DFF7; font-weight:bold'>Clutch</span> types are known for being players with unshakeable nerves, whose skill level rises, and their ability to execute sharpens the higher leverage a situation is. They’re the players a teams rely upon at the end of the shot clock to bail out a possession, or at the end of a game when everything is on the line and a single play is the difference between winning and losing.",

    'On offense, Clutch types are usually the quintessential "tough shot makers." Whether the ball is in their hands or it’s coming to them from a teammate, they are able to get a shot off anywhere on the court, over nearly anyone, and make it a good look no matter how difficult a circumstance it may be. Since everyone knows that the ball is going to the Clutch type in these scenarios, they are often faced with some of the most difficult, single-player-focused, preventative defensive coverages in the game. In a short clock/end of game live ball possession, they’ll almost always have doubles sent to them to get the ball out of their hands. After a timeout when an out of bounds play is drawn up by both teams, opponents are going to look to deny Clutch types the ball if they can, but if they get it, they’ll have the best defender matching up with them, and that double might be on the way as well if time permits.',

    "Since they’re faced with such a difficult task, most offensive Clutch types end up needing a very diverse set of skills in order to get open and be able to reliably generate shots. They will often have above average speed and body control in order to navigate away from traps and through traffic, and a reliable handle that enables them to create space from an opponent that is draped all over them or bust through a double. Being a Clutch type isn’t always about making the biggest shots though, and the best offensive Clutch types will also have the vision and passing ability to let them recognize when they’re in an unwinnable position, and move the ball somewhere else when that’s the right play to make.",

    "Clutch types are extremely effective against defensive Separators in these positions, as the Separator types’ ability to swiftly move in space and get to the rim is not crisp or preemptive enough to be stifling. Clutch types' ability to find space in late game situations is done with a directness and purposefulness that lets them beat most of the best off-ball defenders in the game to a spot. In addition to their ability to get free from defenders, they are also non-hesitating shooters who will find enough room to put up a shot as quick as humanly possible, creating an issue for a more floaty, soft-zoning type of defender like Separators.",

    "Defensive Discipline types will find a similar issue with Clutch types, as their diligent, fundamental, non-reactionary style of defense can make them inflexible and unable to keep up with the freestyling Clutch type. As so many late game and late clock situations are chaotic and difficult-to-predict, a Discipline type may very well fall behind the play having guessed incorrectly and positioned themselves the wrong way while lacking the speed to recover. Ideally, the Discipline type wants to force their opponent into having to break through a sound and sensible defense, but these high leverage moments are often illogical, scattered, or broken plays that require a defense to quickly react and adapt to them. Clutch types excel in these high-risk environments, and since Discipline types are not exactly high-flexibility defenders, let alone the kind of player who is willing to gamble on a play, they are often left stuck in the mud and unable to contest the Clutch types’ shot attempt.",

    "Entangle types may face some issues with the speed of these plays as well, as it can be quite difficult to plant themselves and take root when the ground beneath them is constantly shifting and moving. Since Clutch types are going to be breaking free of their defender and freezing them out of a play, the slower Entangle types are going to be much less effective than normal. Even Entanglers who can keep up and get themselves planted on the Clutch type will find that their contact-baiting style of play, where they are looking to create contact that is advantageous for the defender, is a critical misplay versus a Clutch type. There is already a higher than normal chance of a foul being called against the defender in playing that way, but in a situation where the play is moving at a million miles per hour, a ref is even less likely to call one on the offense. This means that Entangle types are far too likely to bail the other team out by fouling a Clutch type with only a few seconds left on the clock, or worse, foul the best tough shot makers in the game and give up an and-1 - the worst possible scenario in an end of game situation.",

    "The Clutch types’ ability to make extremely tough shots is a big blow to Nullify types’ defense as well. While Nullifiers tend to be very effective against just about any type due to their ability to hone in on a critical strength of a player and take it away, there’s no way to really take away the nebulous skill of tough shot making or their heightened focus in high leverage moments. Whatever a Nullify type tries to remove from the Clutch type, it will not give the Nullifier a critical advantage over them in late clock, late game situations. Matching up with Clutch types ends up being a big negative to the Nullifiers team defense too, as they could be off the ball and on another player, shutting them down, and leaving the Clutch type able to be picked up by a more advantageous defender while allowing the Nullifier to cut off the release valve on the play if the Clutch type gets doubled. Instead, Clutch types have no issue taking the Nullifier on one-on-one and become one of the only types to actually seek out getting a Nullifier matched up onto them.",

    "Clutch types do, however, struggle with players who either have the flexibility to cover all sorts of craziness on the fly, or have enough burst to overwhelm them in an instant. Heat Check types have the perfect combination of burstability and audaciousness as defenders create a huge problem for Clutch types. While an offensive Clutch type is excellent at moving around in these moments of chaos, they are not quite as fast, nor explosive, as a Heat Check type. Heat Check types have likely been storing energy in order to unleash it during these late game situations and can come in after a timeout fresh and ready to instantly blow up a play. This is made easier for them knowing that the ball is almost certainly going to the Clutch player. Even if they can’t lock on to them right away, their ability to be inextinguishable wrecking balls on defense can chase the Clutch type down and away from their desired path, straight into a hot zone where the Heat Check player will evaporate any shot attempt that goes up - if the possession doesn’t completely melt apart before that.",

    "Switchers can employ a similar type of effective, precise, undeterrable movement when looking to stop a Clutch type. While they lack the all-dimensional burst of a Heat Check type, Switchers are velocious in a straight line, becoming bullet trains when they’re able to move along an undisturbed track. This allows them to cut Clutch types off from all sorts of driving lanes, blow past or through screens that the Clutch type uses to get free, and pop out of pick and into coverage on a Clutch type when needed. That quick shiftability in combination with their staunch on-ball defense allows Switchers to contain a Clutch type’s coldblooded shotmaking to a small area, away from the hoop, that can be impossible to work their way out of.",

    "Flow types take a much different, though equally effective approach on defense. Their flexibility and adaptability as defenders strengthens their ability to react and stay connected to all but the quickest type of offensive players. The Clutch type may be quick, explosive, and difficult to deal with in these hectic late clock moments, but it isn’t enough to freeze the constantly moving and engaging Flow type. Defensive Flow types will lean on their exceptional positioning to keep them in areas where they know the Clutch type is looking to get to, staying close enough to keep up, while still remaining adjustable in case they change things up. In addition to a Flow types’ ability to fill in the gaps and shut off a Clutch type in space, their wide variety of skills as on-ball defenders allow them to be comfortable in defending all sorts of shot types and situations. Since the Clutch type excels in being able to make tough shots that may be from strange angles, unbalanced trajectories, turning around, falling down, etc., that type of well-rounded, malleable, adaptive defense from the Flow type can be a real problem, so Clutch types will do whatever they can to be guarded by a less nuanced defender - even going so far as to draw up plays on the opposite side of the court from them.",

    "Offensive Clutch types last difficult matchup is against their defensive counterpart. When two Clutch types are matched up with each other, it creates a very unfavorable matchup for the offensive player, as so much of their strength is negated by mirrored skill set of the defender. Most problematically for the offensive Clutch type, is that the defensive Clutch type excels as a help defender who is able to find the perfect moment to break off of their coverage and freeze an offensive player in place with a double, or come in to spear a shot attempt. While offensive Clutch types have a good sense for how to beat a double, the late game expertise of their defensive counterpart also means that they’re going to double in such a way that it maximizes the difficulty of passing out of the trap or making a contested shot. Players are trying to avoid stalemates in a late game situation as time is of the essence, so offensive Clutch types want nothing to do with the Ice Age standstill created by this matchup.",

    "While defensive Clutch types are effective against a great deal of players, the difficulty and short clock nature of these late game situations result in Clutch types only having a distinct advantage against their offensive counterpart - the enemy within creates the enemy outside, or however that saying goes. Essentially, since these brief moments of time are so variable and the defender doesn’t control the possession as they don’t possess the ball, there simply aren’t many reliably advantageous matchups to be had.",

    "Heat Check types will continue to torch Clutch types on this end. While a defensive Clutch type is a master of timely help defense, if a Heat Check player has the ball in these end of clock situations, there is a high likelihood that they are operating in one of their hot zones. Even if the Clutch type reads the play well and comes over to double a Heat Check player, waltzing into a hot zone will see them flying far too close to the sun, and a savvy Heat Check type will have either converted before the Clutch type can get there, or have a great chance for an and-1 when they do.",

    "Switchers remain troublesome matchups for Clutch types when their roles are reversed as well. While they’re not going to get the ball in late game situations very often, the two conditions in which they’ll be used are tough for Clutch types to defend against. The first way they’re used is as connective passers in an out of bounds play or set, where they’ll receive the ball from the inbounder with the intention of getting it right back to them or finding someone involved in an off-ball action. If a Clutch type is guarding the Switcher, that will force them to stay connected to them just long enough that it can hamper their ability to relocate and help out once the ball is out of the Switcher’s hands - especially given the fact a Switcher is likely going to follow the ball and push the Clutch type out of the play if possible. The second way in which they are used is as devastating off-ball cutters who can come in like freight trains at the end of a play when the defense has over-focused on the ball. A Clutch type is going to be lured to the ball the longer a possession draws out, eventually untagging from a Switcher, and allowing the Switch type to get a back cut to the basket that will be near impossible to react to and contest in the moment.",

    "Clutch types will also struggle with Stonewall and Physical types for a similar reason. While both of these players are unlikely to be the first or second option for a play in a late game situation, and they probably won’t have the ball in their hands either, they do excel at offensive rebounding and finishing or cleaning up plays around the rim. A Clutch type can easily find themselves out of position after contesting a shot in a late clock situation, having left their man to ensure that the shooter misses the shot, but in turn gave up on boxing out or sealing their original cover and left themselves unable to defend against a putback or dump off. Even if they’re able to react to the second chance opportunity, Stonewall and Physical types' strength and size can break through a Clutch type's rim defense and find a way to get fouled, despite being forced into a tough shot.",

    "<span style='color: #81DFF7; font-weight:bold'>Historical Offensive Clutch Types:</span> <span style='font-weight:bold'>John Havlicek, James Worthy, Chauncey Billups, Robert Horry.</span>",

    "<span style='color: #81DFF7; font-weight:bold'>Historical Defensive Clutch Types:</span> <span style='font-weight:bold'>Bill Walton, Dennis Johnson, Tayshaun Prince, Gerald Henderson.</span>",
  ],
  [
    "<span style='color: #5060E1; font-weight:bold'>Nullifiers</span> are perhaps the most unique type in the game. While Nullifiers all tend to be quite athletic, unlike other players, they share very few similarities in their skill sets. One offensive Nullify type may score in an entirely different fashion from another, or a defensive type might play a style of on-ball defense that some other Nullifier doesn’t vibe with at all. Rather than their specific styles or skills being what binds them together, it is the distinct manner in which they gain an upper hand on other players and how they use that advantage.",

    "While just about every other type has a clear style that they use to best their opponent, Nullify types succeed not by pushing an advantage innate to their skill set, but by honing in on the opposing player’s ability and finding a way to take that away from them. In doing so, they’re able to break apart the foundation of another player, and then hammer at the weak spot they created over, and over, and over again until their opponent becomes a shell of themselves.",

    "On offense, Nullifiers are unrelenting predators and their ability to negate something critically important to an opponent allows them to score effectively against just about every player type in the game. Funnily enough, they only have a truly outlier advantage on offense - themselves! Since Nullify types function by taking something away from another player and then going at the weak point they created, the defensive Nullify type runs into an issue where there isn’t any one distinct thing to take away from an offensive Nullify types’ game. While the offensive Nullify type will not find something to take away from the defensive type either, the offensive player holds the advantage as they move first, meaning that the defender is always going to have to react, and with nothing to take away to make up the distance, Nullifiers with the ball in their hand are always going to have high ground in this match up.",

    'Though offensive Nullifiers have a significant advantage against just a single type, they also only struggle with two types of defenders. The first is Switchers, who also have a general strength as athletes and as defenders and do not have a singular foundational skill that is able to be removed from them. Rather than a specific hard skill, a Switch type’s main strength is their ability to freely flip onto/off of offensive players in the midst of an active play. Since that is a "soft skill" that a Switcher uses to navigate multi-player interactions on defense, it’s not something a Nullifier can really target and repossess. If a Nullify type is being guarded by a Switcher then they’ve found themselves going against a very staunch on-ball defender, and if the Nullifier tries taking away something else from, say, a dual-type Switcher, then the Switcher can easily flip to another player and let someone else take the Nullify type. Additionally,, if the Nullifier wants to use a screen to try and get the Switcher off of them, the Switch type will expertly navigate the screen to stay locked in on the Nullifier while making sure the teammate the Nullifier is trying to target makes the right adjustment and keeps glued to their original cover instead.',

    "The second type they have an issue with, and issue is an understatement, are Teamwork types. Teamwork types excel at communication and orchestrating their team’s defense while on the floor, basically like a secondary coach. They are x’s and o’s savants who make few mistakes thanks to tireless preparation and always knowing where everyone should be, at all times, for both teams. Not only does the Nullifier have no real weak point to expose in the Teamwork type one-on-one, but even when the Teamwork type isn’t guarding them, they will be shot calling for the defense the entire time and guiding players to where they’re supposed to be, bringing doubles, and killing plays before the other team can run them. The offensive Nullifier  can’t take that ability away from the Teamwork type and will find it hard to ever get a good read on an opponent because of this. Even if they do get a good read on someone and start to pick them apart, the Teamwork type will find ways to move that player off of them and render the Nullify type’s ability to expose that defender completely ineffective.",

    "On defense, Nullifiers have a few more matchups that they find explicit advantages in. They are quite good against Heat Check types, who rely on being able to find a scoring rhythm by getting to their hot zones and putting shots up. Nullifiers will quickly pick up on the areas of the court that Heat Check players are always moving to and focus their attention on taking away their paths to do so. Once the Nullifier cuts those areas off, the Heat Check type will lose their scoring fuel and either rapidly cool off, or never be able to catch fire in the first place",

    "Nullify types also have a clear advantage against Flow types, though they approach this matchup quite differently than other Flow-stifling players. While Flow types should have an innate edge versus a Nullifier due to their highly adaptive skill set and ability to do so many different things on the court, their issue is that the effectiveness as a player is ultimately reliant on being able to leverage the threat to do no less than all of those things. In other words, a Flow type needs to have everything in order to have anything, and removing one part of their game will make everything else that much less effective. Since Flow types are often great because of their wide variety of skills, this also means that they’re not likely to be overwhelmingly great at one single skill. As such, a Nullify type can completely take away a fundamental part of a Flow type’s toolkit, like their scoring or passing, which in turn creates a dam that blocks a Flow type from being able to access and employ their other skills at a high level and makes them a much less dynamic and threatening offensive player.",

    "Entangle types will also find very little success on offense when being defended by Nullifiers. Since offensive Entanglers are looking to slowly work their way into a defender and create contact, the Nullify type's ability to entirely negate that by playing a hard-nosed, anti-fouling type of defense is a devastating blow to the Entangle type’s scoring ability. Nullify types will key in on all the ways the Entangle type is trying to draw fouls by letting them dig down, root themselves into the defense, and watch as they try and tangle them up. As a result, the Nullifier will quickly figure out all the various pitfalls, when to  never bite on a fake, and how to keep away from the falling tree.Entangle types will spend possession after possession getting into a position to try and score or attempting to get a call, but are met by diligent, quick, hyper-aware defense from a Nullifier that will darken the sky and starve their appetite for free throws. Entanglers will do their best to force the contact but it will be to no avail, leaving them with their branches up, stuck to the ground, unable to get some points on the board, and slow to back on defense.",

    "Nullify types will be terrifying defenders for Highlight types, who are also looking to force the defender into playing at their specific speed. Unfortunately for Highlight types, defensive Nullifiers more or less cannot be forced into playing at a tempo they are uncomfortable with. While Highlight types succeed by making their defender have to play far too quickly and reactively than they are comfortable with, Nullify types have no real issue with the Highlight types’ speed. Though they may not be as lightning-quick, Highlight types are rather simple to figure out for the Nullify type, given that their insistence upon attacking shortly into the shot clock is all but a given. Nullify types have a unique combination of quickness and size that enables them to jam Highlight types up on-ball as soon as they cross half court, making the Highlight type unable to easily get away from them no matter what feats of agility they may attempt. Whether they have the ball in their hand or they’re off-ball cutters looking to bolt to the rim, Highlight types will end up expending all of their energy just to navigate into a no-win situation, like light being pulled into a black hole, and are liable to turn the ball over to the Nullify type time and time again.",

    "Defensive Nullifiers really only struggle with two offensive types, outside of their aforementioned offensive counterparts. The first is, unsurprisingly, the Teamwork type. Teamwork types’ mastery of the x’s and o’s and ability to move their team around remains an extremely potent counter to the Nullifier on this end as well. Teamwork types’ core ability is enhancing and empowering their teammates by setting plays up, dictating the movement of the offense, getting the ball to the right place at the right time, and keeping things running smoothly. This allows Teamwork types to find ways to move the ball away from Nullifiers when they have a good match up, getting players that the Nullifier has in their gravitational pull away from the danger. Teamwork types will even try to get the Nullifier switched onto them so that they can. Making scheming the offense away from a Nullifier a simple task, and shutting them out of the play all together.",

    "Nullifiers final issue on defense is with offensive Clutch types. Part of the issue Nullifiers have with Clutch types is that they do not have an innate skill to take away. While they have a great deal of skills that enable them to succeed in these late game situations, it is their instinct and ability to succeed in the most difficult positions that is the core of their value. Even Clutch types who may have distinct skills the Nullify type could look to take away, in the end of game and end of clock situations that a Clutch type is best used in it is so hectic and chaotic that the Nullifier would have to guess which one to try and negate. Even if the Nullify type were to guess right and strip away a major component of an out of bounds play for instance, Clutch players have that special something about them that allows them to overcome the impossible and drain the shot. As such, it’s usually better to have the Nullify type kill a different part of the play and off of the Clutch type - at least as the primary defender.",

    "<span style='color: #5060E1; font-weight:bold'>Historical Offensive Nullify Types:</span> <span style='font-weight:bold'>Michael Jordan, Dwyane Wade, Drazen Petrovic, Jeff Hornacek.</span>",

    "<span style='color: #5060E1; font-weight:bold'>Historical Defensive Nullify Types:</span> <span style='font-weight:bold'>Hakeem Olajuwon, Tony Allen, T.R. Dunn, Clifford Ray.</span>",
  ],
  [
    "You can think of the <span style='color: #F5A2F5; font-weight:bold'>Teamwork</span> type as a watchmaker, their discerning eye and steady hand allowing them to control the tempo and pace of a game by making sure each and every gear is in the right place and firing at the right time. They are the quintessential leaders of the game, PhDs in the x’s and o’s of basketball who are able to act as secondary coaches while out on the floor and keep things running smoothly.",

    "While Cerebral types display a similar mastery of the game, they use it to enhance their own skill first and foremost. Where Teamwork types differ, and what makes them a one-of-a-kind type, is that they use their deep understanding of the game as a way to primarily enhance the play of their teammates. Rather than locking up the best scorer on the opposing team or keeping the ball in their hands at all times and dictating every possession’s outcome, Teamwork types want to set things up so that their teammates are able to work as a well oiled machine, make the right decisions, and find success in the system.",

    "On offense, Teamwork types are almost always going to be their team’s point guard. While they are not as ball dominant as a Cerebral type point guard, they are still incredible decision makers and should be the player who gets offensive possessions started. Teamwork types function best inside of highly structured offenses that they can master the ins and outs of, not just for themselves, but their teammates as well. They will know where everyone is supposed to be at, at all times, and most likely how the defense is going to react to what they’re doing. As such, Teamwork types will often be communicating with their coach on the sideline to pick up what plays to run, what adjustments to make, and then relaying that to their teammates. Though, the Teamwork type is likely able to pick up on all of this without having to glance at the sideline.",

    "Teamwork types excel against Physical types on offense, as they’re able to leverage the Physical types’ aggression against them and run them all over the floor. Physical types are either looking to lock down their matchup with vice grip man defense, or sprint to extremely hard doubles on players and blow up a possession like a drop kick on a plywood door. Since Teamwork types are always looking to get the ball out of their hands quickly, and implore their teammates to do the same, that type of defense against a Teamwork type becomes a total over-commitment by the Physical type who will look like the opposing team is playing keepaway against them. A Physical type will tire out quickly from having to follow the passes or recover to their man, and their hard closeouts are highly susceptible to being back cut by players who play with a Teamwork type.",

    "Nullify types also struggle with Teamwork types, as their primary focus on defense is to try and take away an opposing player’s strength. Teamwork types’ strength comes from their ability to play sound basketball, understand the plays of both teams, control the pace of the game, and make sure their team has excellent ball movement. None of these things fall under the category of a singular skill that can be removed from the Teamwork type, so Nullifiers will find no clear weakness to expose in them. Additionally, a Teamwork type’s ability to expertly orchestrate offenses and move players around means that they can find ways to get Nullifiers off of exposable teammates, thus flipping the script and nullifying the Nullifier!",

    "Offensive Teamwork types will struggle with types that have ways of combating or controlling the movement of an offense, or hurt the Teamwork type's ability to set up a play. One of the types that can greatly stifle them are Isolationists, who are able to cordon off a Teamwork type from their team and make it so they cannot run plays as the ball handler. Isolationists are experts at sticking on a player and forcing them into one-on-one situations, which is the opposite of what a Teamwork type is trying to have happen with their offense. Not only are Teamwork types often poor one-on-one players themselves, but Isolationists can also stick on a vital teammate to the Teamwork types’ system, making it difficult for that player to participate in the offense at all and misaligning the gears of the offense.",

    "Switchers pose a similar problem in regards to the movement of a Teamwork type’s offense. While Isolationists can suffocate a Teamwork type by forcing them away from their team, Switchers are able to navigate the offense of a Teamwork type’s impeccably. There is perhaps no better defender against a Teamwork type who is looking to move the ball around, set up plays with lots of off-ball movement, screens, etc., than a Switcher. They are able to expertly navigate all of the off-ball pick plays and movements, guard multiple positions, and kill the passing lanes created from screeners. Despite their best efforts, Teamwork types will not be able to find ways to move their team around, and move the ball around, that can penetrate a Switcher’s steely defense. If Teamwork types are the watchmaker, Switchers are the wrench dropped into the gears of the mechanism that renders it inoperable.",

    "Heat Check types have a more all encompassing way to stifle the Teamwork type and their offense. Heat Check types hot zones act as must-avoid areas for the Teamwork type and their offense, making it so swaths of the court are unable to be used and shrinking the availability of space and plays available to the Teamwork type. If a Teamwork type gets into one of these areas themselves, they’re going to be unable to escape or kick the ball out before a Heat Check type engulfs them. If the Teamwork type instructs a teammate into it, or runs a play that goes through it, the Heat Check type can burn that play to the ground. Teamwork types need to be able to have great ball movement on offense to succeed, and having to avoid entire areas of the court like they were hot lava makes that significantly harder.",

    "On defense, Teamwork types are just as team focused as their offensive selves. Defensive Teamwork types’ individual skill may be a bit more potent to make up for the lack of impact the offensive types get from having the ball in their hand, but they are just as much of a vocal leader and shot caller. Defensive Teamwork types will call out coverages, call out the opposing teams plays before they can even run them, get guys in positions and tell them where to go, and swarm opposing offenses  with the perfect defensive strategy.",

    "They continue to excel against Physical types who lack the nuance in their offensive game to combat what the Teamwork type can throw at them. Physical types will be hit with a ton of help defense, double teams, clogged driving lanes, and other strategies by the Teamwork type and their squad, preying on their lack of ball handling and off the dribble options in order to shut them down. The teamwork type themselves will often take responsibility for being the main cover on a Physical type, guiding them into these problem spots and calling for help to come at the right time. Physical types who are used as obstructive screeners on offense rather than scoring threats will see their value minimized by the Teamwork type who will pierce the heavens with calls of “screen left, screen right!”, and constant direction to teammates for how they should cover and react.",

    "Pest types will be similarly overwhelmed, as the darting, bouncing, probing players will be promptly swarmed by the Teamwork type. While Pests are excellent at navigating space and getting into the teeth of a defense, Teamwork types will set traps all over the floor to lure the Pest types in, and close off their path to escape. Pest types are looking to burrow their way into the opposing team and cause confusion with their erratic movement, but Teamwork types will be all over this and not be overwhelmed. There are few things more difficult for a Pest type to deal with than a perfectly timed double team, and Teamwork types can roll out a hundred different coverages that’ll leave the Pest stuck with nowhere to go.",

    "Defensive Teamwork types are hyper-effective against Nullifiers, as the skill-negating dominators will have nothing to take from the Teamwork type, and no way to take away something from an entire team at once. Not only are Teamwork types devoid of a skill that Nullifiers can expose, but their strength in the ability to orchestrate their teammates means that the Nullifier is going to spend all game seeing different coverages, different defenders, and double teams to force the ball out of their hand. The Nullifier will need time against an opponent to discover a weakness, but Teamwork types know this, and will mix up coverages and personnel on them so the Nullify type can never get enough of a read on their opponent to start hunting them. This is extremely disorienting for Nullifiers and results in them being completely ineffective against a Teamwork type and significantly less effective against their teammates.",

    "Teamwork types will continue to struggle with Isolationists on defense, as their ability to cut the Teamwork type off from their team and force one-on-one possessions is deadly as ever. Teamwork types can struggle to find ways to not be targeted by Isolationists who are experts at singling out a target and moving them to an area that is difficult to call for help. Since the Teamwork type is so dependent on being able to call things out for their team and set up coverages, this solitary confinement spells real trouble. Isolationist possessions are often just two players matching up with the Isolation player creating a scoring opportunity before a double can come, and as such, Teamwork types have very few options to help themselves or help their teammates against Isolation types.",

    "Switchers continue to pose problems for them as well, as their two-pronged approach on offense can be a bit of a difficult playstyle to diagnose. The Teamwork type is not quite as good at accounting for other playmakers, nor are they excellent at figuring out how to stop physical, athletic, strong-but-in-control cutting actions. Since the Switcher is able to take on both of these roles, and do so as a tertiary option, Teamwork types will not only be unable to find a distinct advantage against their playstyle, but may lose track of them all together as they’re rarely the main focus or option of a play. This allows Switchers to fly under the radar while the Teamwork type is focused on stopping more potent options and find just the right time to take advantage in the defensive lapse.",

    "<span style='color: #F5A2F5; font-weight:bold'>Historical Offensive Teamwork Types:</span> <span style='font-weight:bold'>Fat Lever, Lamar Odom, Terry Porter, Jose Calderon.</span>",

    "<span style='color: #F5A2F5; font-weight:bold'>Historical Defensive Teamwork Types:</span> <span style='font-weight:bold'>Kevin Garnett, Bobby Jones, Buck Williams, Steve Mix.</span>",
  ],
];

export const typeTables = [
  /* Generalist */

  [
    {
      header:
        'C or Above Grade In 80% Of Role Adj. Off Metrics<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'C or Above Grade In 80% Of Role Adj. Def Metrics<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Fewer Outlier Grades In Role Adj. Off Metrics<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Fewer Outlier Grades In Role Adj. Def Metrics<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Lower Isolation Frequency<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value:
        'C or Above D Role Versatility Grade<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Fewer Offensive Fouls / 75 Poss",
      value: "Fewer Defensive Fouls / 75 Poss",
    },
    {
      header: "",
      value: "Less Loose Balls Recovered / 75 Poss",
    },
  ],

  /* Physical */
  [
    {
      header:
        'Screen Assists / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Rim dFG% vs. Expected<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'OReb Crashing Skill<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Adjusted Box Out Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'OReb Positioning<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Real Adjusted DReb Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'OReb Conversion Skill<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Post Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Real Adjusted OReb Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'On-Ball Perimeter Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Rim Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "More Def Fouls Committed",
    },
    {
      header:
        'Drive & Post-Up Foul Drawn Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Contested DReb%<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "More Off Fouls Committed",
      value:
        'Adj. Box Out Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Teammate Movement Scoring Impact<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Higher Transition, Cut, Putback, Roll-man Play Type Frequency/PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Lower Isolation, Spot, Handler Play Type Frequency/PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Higher Non-Pass TOV%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "",
    },
  ],

  /* Separator */
  [
    {
      header:
        'Lower 3PT Openness Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Off-Ball Chaser Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Higher 3PTA Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Ball Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Avg. 3PT Shot Distance<span class="custom-hyphen"> -</span><a href="https://sportradar.com/?lang=en-us" target="_blank" style="font-style: italic;">SportRadar</a>',
      value:
        'Loose Ball Recovery Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        '3PT Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Lower Adj. Box Out Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        '3PT Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Lower Def Reb Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        '3PT Shooting Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Lower Post Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    { header: "Higher Offensive Speed", value: "" },

    {
      header:
        'Off Screen Share<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Time Off-Ball %<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },

    { header: "% Of Points As 3s", value: "" },
    { header: "Drives / 75 Poss", value: "" },
    { header: "Lower Drive Foul Drawn Rate", value: "" },
    { header: "Dunks Per Paint FGA", value: "" },
    { header: "3PT% On Tight Coverage FGAs", value: "" },
    {
      header:
        'Movement Dist. Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },

    {
      header:
        'Higher Transition, Spot, Off-screen, Hand-off Play Type Frequency/PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    { header: "Lower Post Frequency / Paint Touches", value: "" },
  ],
  /* Isolationist */
  [
    {
      header:
        'On-Ball %<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Isolation Defense PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
    },
    {
      header: "Avg Drib Per Touch",
      value:
        'On-Ball Perimeter Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Self-Created Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Post Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'One-on-One Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher Matchup Difficulty<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a',
    },
    {
      header:
        'Midrange Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher %Time Defending Shot Creators<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Defensive Playmaking<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Higher Isolation Frequency/PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value:
        'Lower Def Speed<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Isolation Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Isolation Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Midrange Pull Up%<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Stable Points / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Lower Off Speed<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
  ],
  /* Discipline */
  [
    {
      header:
        'Rim Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Fewer Def Fouls vs. Expected",
    },
    {
      header:
        'Rim Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Rim Deterrence<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Rim Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Post Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Paint Touches / 75 Poss",
      value:
        'Adj Def Reb %<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Cut Share<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'On-Ball Perimeter Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Isolation Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Post Defense PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
    },
    {
      header:
        'Lower Isolation Turnover Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    { header: "Fewer Off Fouls Committed", value: "" },
    {
      header:
        'OReb Conversion Skill<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Less Helper Impact If MonoType<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'OReb Positioning<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Lower Shot Location Variety<span class="custom-hyphen"> -</span><a href="https://x.com/GabeLeftBrain" target="_blank" style="font-style: italic;">Gabe Guzman Shot Charts</a>',
      value: "",
    },
  ],

  /* Stonewall */
  [
    {
      header:
        'Finishing Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Rim Protection<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Higher % Of Rim FGA Assisted",
      value:
        'Rim Deterrence<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Drives / 75 Poss",
      value:
        'Screener Rim Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Cuts / 75 Poss",
      value:
        'Help Defense Activity<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Drive Foul Rate",
      value:
        'Help Effectiveness Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },

    {
      header: "Screen Assists / 75 Poss",
      value:
        'Defensive Rebounding Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Slip Impact / 75 Poss",
      value:
        'Less Def Role Position Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    { header: "Roll Impact / 75 Poss", value: "More Def Fouls vs. Expected" },
    {
      header:
        'P&R Roll Man PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value:
        'Post Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Team Roll Man Share %<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Non Pass TOV%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "",
    },

    {
      header:
        'Rim+FT TSA%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "",
    },

    {
      header:
        'Higher Cut, Putback Frequency/PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Lower Shot Location Variety<span class="custom-hyphen"> -</span><a href="https://x.com/GabeLeftBrain" target="_blank" style="font-style: italic;">Gabe Guzman Shot Charts</a>',
      value: "",
    },
  ],

  /* Pest */
  [
    {
      header:
        'Cut Share<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Pickpocket Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Off Screen Share<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Loose Ball Recovery Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },

    {
      header:
        'Movement Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Passing Lane Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Movement Speed Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Steals / 75 Poss",
    },

    { header: "Drives / 75 Poss", value: "Deflections / 75 Poss" },

    {
      header:
        '% Of Rim Unassisted<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Off-Ball Chaser Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },

    {
      header:
        'Drive Passout Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },

    { header: "Fewer Screens / 75", value: "More Defensive Miles / 75 Poss" },

    {
      header:
        'Poor Rim Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Higher Fouls Drawn Rate",
    },
    {
      header: "",
      value:
        'Lost Ball Steals<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "",
      value:
        'DHO Coverage Aggression<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "",
      value: "Higher Average Defensive Speed",
    },
  ],
  /* Tricky */
  [
    {
      header:
        'PnR Ball Handler Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Stable Charge Fouls Drawn / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'On-Ball Gravity<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Pickpocket Rating<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Non-Shooting Fouls Drawn / 75 Poss",
      value: "Steals / 75 Poss",
    },
    {
      header:
        'Iso Foul Drawn Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Off-Ball Chaser Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Lower Isolation Turnover Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Passing Lane Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Stable Pull Up 3PT%",
      value:
        'Ball Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "3PT Foul Rate",
      value:
        'Defensive Reb Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Drive Passout Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Passing Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Passing Creation Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
  ],
  /* Switcher */
  [
    {
      header:
        'Rim Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher On-Ball Perimeter Def:Off-Ball Chaser Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Drive Foul Drawn Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher Post Defense:Help Defense Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'OReb Conversion Skill<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Screener Mobile Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Putbacks / 75 Poss",
      value:
        'Block Rate On Attempts<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Cut Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Fewer Blocks / 75 Poss",
    },
    {
      header:
        'Less % Of Rim Unassisted<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Fewer Steals / 75 Poss",
    },
    {
      header:
        'Roll Man Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher D Position Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Higher 3PT Shot Openness<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
  ],
  /* Heat Check */
  [
    {
      header:
        'Perimeter Shooting<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Steals / 75 Poss",
    },
    {
      header:
        'Finishing Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Blocks / 75 Poss",
    },
    {
      header:
        'Midrange Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Higher Block Rate on Contests<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Higher Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Lower Opponent Transition PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
    },
    {
      header:
        'Post Up Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header: "Lower Shot Quality",
      value: "",
    },
    {
      header:
        'Pull Up Midrange FG%<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Pull Up 3PT%<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header: "Scoring Possessions / 75 Poss",
      value: "",
    },
    {
      header:
        'Higher +eFG Shot Location Concentrations<span class="custom-hyphen"> -</span><a href="https://x.com/GabeLeftBrain" target="_blank" style="font-style: italic;">Gabe Guzman Shot Charts</a>',
      value: "",
    },
    {
      header:
        'Higher Spot Up, Isolation, Handler, Isolation Play Type Frequency / PPP / PoE<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header: "Higher PPM",
      value: "",
    },
    {
      header: "Significantly Higher PPM As Non-Starter",
      value: "",
    },
  ],
  /* Flow */
  [
    {
      header:
        'Higher & Similar All Play Type Stable PPP<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'D Role Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Higher & Similar Finishing/Shooting Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Usage Tiers Defended Variety<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Equal Roll:Pop:Split Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Matchup Adj. Def Feet/MP<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Equal Straight:Fade:Flare:Curl Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Ball Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Equal Jumper:To Rim:Hook:Up & Under Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Passing Lane Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Equal Flash:Screen Cut Ratio<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Def Reb Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Role Adj. Ast Pts / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'High Value Ast / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: " ",
    },
    {
      header:
        'Shot Location Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: " ",
    },
    {
      header: "Touches Per Game",
      value: " ",
    },
    {
      header:
        'Higher Shot Location Variety<span class="custom-hyphen"> -</span><a href="https://x.com/GabeLeftBrain" target="_blank" style="font-style: italic;">Gabe Guzman Shot Charts</a>',
      value: " ",
    },
  ],
  /* Entangle */
  [
    {
      header: "Iso Foul Drawn Rate",
      value:
        'Defensive FT Rate Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Drive Foul Drawn Rate",
      value: "Offensive Fouls Drawn / 75 Poss",
    },
    {
      header: "Post Up Draw Foul Rate",
      value:
        'Real Adj. TOV Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "3PT Foul Rate",
      value:
        'Time Spent Guarding Usage Tier 2/3 Opp<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Stable Shooting Fouls Drawn / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Ball Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "FTA / 75 Poss",
      value:
        'Poor DReb Positioning<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Lower And-1 Conversion %",
      value:
        'Forced TOV%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
    },
    {
      header: "Closer FGA:FTA Ratio",
      value: "",
    },
    {
      header:
        'Offensive FT Rate Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'On-Ball Gravity<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header: "Points Per Paint Touch",
      value: "",
    },
    {
      header: "Higher Points Per Shot",
      value: "",
    },
  ],
  /* Highlight */
  [
    {
      header:
        'Rim Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Ball Screen Navigation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Finishing Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Loose Ball Recovery Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Adj. Drives Per 75 Poss & Height < 6ft5<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Steals / 75 Poss",
    },
    {
      header:
        'Playmaking Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Block Rate on Contests At Rim",
    },
    {
      header:
        'Higher Handoff, Cut, Handler, Transition Play Type Frequency / PPP / POE<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value:
        'DHO Coverage Aggression<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Real Adj. OReb Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Real Adj. DReb Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Higher Turnovers / 75 Poss",
      value: "Goaltends / 75 Poss",
    },
    {
      header:
        'Higher Handoff, Cut, Handler, Transition Play Type Frequency / PPP / POE<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header: "Top Speed",
      value: "",
    },
  ],
  /* Cerebral */
  [
    {
      header:
        'Box Creation<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value:
        'Passing Lane Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Passer Rating<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "Deflections / 75 Poss",
    },
    {
      header:
        'PlayVal<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value:
        'Real Adj. TOV Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Passing Creation Volume<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'More Time Spent Defending Primary Ball Handler<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Passing Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Defensive eFG% Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Passing Creation Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'On-Ball Perimeter Defensive<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Role Adj. Ast Pts<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Forced TOV%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
    },
    {
      header:
        'On-Ball %<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Teammate Help Defense Activity<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
    },
    {
      header:
        'Time Of Possession<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value:
        'Matchup Difficulty<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
    },
    {
      header:
        'Load<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "",
    },
    {
      header:
        'On-Ball Gravity<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
    {
      header:
        'Isolation PPP When Defense Commits<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Initiator Rate<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header: "% Of Rim Unassisted",
      value: "",
    },
    {
      header:
        '3PT Shot Creation<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "",
    },
  ],
  /* Clutch */
  [
    {
      header:
        'Clutch Win Probability Added<span class="custom-hyphen"> -</span><a href="https://stats.inpredictable.com/nba/jerryWest.php?" target="_blank" style="font-style: italic;">Inpredictable</a>',
      value:
        'Clutch Win Probability Added<span class="custom-hyphen"> -</span><a href="https://stats.inpredictable.com/nba/jerryWest.php?" target="_blank" style="font-style: italic;">Inpredictable</a>',
    },
    {
      header:
        'Off Prod In Clutch Time<span class="custom-hyphen"> -</span><a href="https://82Games.com" target="_blank" style="font-style: italic;">82Games</a>',
      value:
        'Def Prod In Clutch Time<span class="custom-hyphen"> -</span><a href="https://82Games.com" target="_blank" style="font-style: italic;">82Games</a>',
    },
    {
      header: "Time Of Poss In Clutch Time",
      value: "Forced TOV Under 24s Left In Game",
    },
    {
      header: "Off Prod Under 24s Left In Game",
      value: "Forced TOV Under 10s Left In Clock",
    },
    {
      header: "Off Prod Under 10s Left On Shot Clock",
      value:
        'Forced TOV When Behind/Tied In Clutch<span class="custom-hyphen"> -</span><a href="https://pivotfade.com/" target="_blank" style="font-style: italic;">PivotFade</a>',
    },
    {
      header:
        'Tight / Contested Shot Making PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value: "",
    },
    {
      header:
        'Total Points When Behind/Tied In Clutch<span class="custom-hyphen"> -</span><a href="https://pivotfade.com/" target="_blank" style="font-style: italic;">PivotFade</a>',
      value: "",
    },
  ],
  /* Nullify */
  [
    {
      header:
        'Movement Impact<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Matchup Difficulty<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Off Screen Share<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Def Position Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Post Up Draw Foul Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Def Role Versatility<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Putback Impact / 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'On-Ball Perimeter Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Rim Shot Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Passing Lane Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        '3PT Shot Making<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Time Spent Guarding Usage Tier 1/2 Opp<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Real Adj. OReb Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Time Spent Guarding Shot Creators<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'On-Ball Gravity<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Isolation Defense PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
    },
  ],
  /* Teamwork */
  [
    {
      header:
        'Passing Creation Quality<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Loose Ball Recovery Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'High Value Ast/ 75 Poss<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value: "Deflections / 75 Poss",
    },
    {
      header:
        'Passing Creation Volume<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Adj. Box Out Rate<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Secondary Assists<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
      value:
        'Opponent Transition/Fastbreak PPP<span class="custom-hyphen"> -</span><a href="https://synergysports.com/sport/basketball/" target="_blank" style="font-style: italic;">Synergy Sports</a>',
    },
    {
      header:
        'Playmaking Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
      value:
        'Help Defender Talent<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header: "Passes Per Game",
      value: "Lower Fouls Committed",
    },
    {
      header: "Touches Per Game",
      value:
        'Rim Deterrence<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'Good Pass:Bad Pass Ratio<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value:
        'Off-Ball Chaser Defense<span class="custom-hyphen"> -</span><a href="https://www.bball-index.com/glossary/" target="_blank" style="font-style: italic;">BBIndex</a>',
    },
    {
      header:
        'PlayVal<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',
      value: "",
    },
    {
      header:
        'Handle TOV%<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball)</a>',
      value: "",
    },
    {
      header:
        'Passer Rating<span class="custom-hyphen"> -</span><a href="https://www.thinkingbasketball.net/" target="_blank" style="font-style: italic;">Thinking Basketball</a>',

      value: "",
    },
    {
      header: "Points:Assists Ratio",
      value: "",
    },
  ],
];
