import React from "react";
import TV from "../../assets/TV.png";

function VideoSection() {
  return (
    <div className="TV">
      <img src={TV} alt="TV" />
      <div className="video-section">
        <iframe
          src="https://www.youtube.com/embed/wWUYEs_1ae8?si=NvKtOy5R8EZoPzTY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default VideoSection;
